export default {
  PersistentVolume: "ボリューム",
  persistentVolumeName: "ボリューム名称",
  persistentVolumePath: "グマントパス",
  persistentVolumeReclaimPolicy: "再利用ポリシー",
  PersistentVolumeClaims: "ボリュームクレーム",
  claimRef: "永続ボリューム申請",
  volumeName: "永続ボリューム",
  storage: "容量",
  accessModes: "アクセスモード",
  storageClassName: "ストレージタイプ",
  volumeMode: "ボリュームモード",
  persistentVolumeSource: "ボリュームの出所",
  volumeID: "ボリュームID",
  fsType: "ファイルシステム",
  readonly: "読取専用",
  nfsServer: "NFSサーバーホスト",
  nfsPath: "NFSアクセスパス"
};
