export default {
  application: "Applications",
  Deployment: "Deployment",
  StatefulSet: "StatefulSet",
  CronJob: "CronJob",
  DaemonSet: "DaemonSet",
  applicationTopo: "Application Topology",

  applicationSteps: {
    first: "Basic Configuration",
    second: "Pods Configuration",
    third: "Service Configuration"
  },

  applicationName: "Application name",
  applicationType: "Application type",

  replicas: "Replicas",
  terminationGracePeriodSeconds: "TerminateGracePeriodSeconds",
  podManagementPolicy: "PodManagementPolicy",
  OrderedReady: "OrderedReady",
  Parallel: "Parallel",
  strategy: "Update strategy",
  updateStrategy: "Rolling update strategy",
  RollingUpdate: "Rolling update",
  OnDelete: "OnDelete",
  partition: "Partition",
  volumeClaimTemplates: "Volume claim template",
  volumeClaimTemplatesName: "Template name",
  schedule: "Schedule",
  concurrencyPolicy: "Concurrency policy",
  startingDeadlineSeconds: "Starting deadline seconds",
  suspend: "Suspend status",
  activeDeadlineSeconds: "Maximum active running time for single job",
  backoffLimit: "Backoff retry limitation",
  parallelism: "The number of pod parallel for single job",
  completions: "The expected number of completed pod for single job",

  containerAdd: "New container",
  containerName: "Container name",
  containerConf: "Container configuration",
  containerStatus: "Container Status",

  nodePort: "Ports",
  env: "Environment variables",
  liftCycle: "Lifecycle hooks",
  startCommand: "Start command",
  runningParams: "Arguments",
  runningCommand: "Commands",
  postStart: "Post start actions",
  preStop: "Pre stop actions",
  handleType: "Action mode",
  command: "Commands",
  exec: "Exec mode",
  httpRequest: "HTTP probe",
  tcp: "TCP probe",
  health: "Health check hooks",
  checkType: "Check mode",
  livenessProbe: "Liveness probe",
  initialDelaySeconds: "Initial detection time in seconds",
  periodSeconds: "Detection period time in seconds",
  timeoutSeconds: "Detection timeout time in seconds",
  readinessProbe: "Readiness Probe",
  successfulJobsHistoryLimit: "Maximum history limit of successful jobs",
  failedJobsHistoryLimit: "Maximum history limit of failed jobs",

  podName: "Pod name",
  podIP: "Pod IP",
  hostIP: "Host IP",
  restartTimes: "Restarts",
  logs: "Application console logs",
  terminal: "Web terminal",
  pod: "POD",
  container: "Containers",

  scaleByHand: "Manually Scaling",
  autoScale: "AutoScaling",
  autoScaleSwitch: "Autoscaling toggle",
  autoScaleResource: "Resources",
  cpuThreshold: "CPU threshold",
  memoryThreshold: "Memory threshold",
  replicasRange: "Replicas range",

  podStatus: {
    Initialized: "Initialization",
    Ready: "Application Pod ready",
    ContainersReady: "Containers ready",
    PodScheduled: "Pod Scheduled"
  },

  hostAliases: "Host Aliases",
  alias: "Hosts",

  lastSchedule: "Last scheduled at",
  nextSchedule: "Next schedule at",
  activeCronJob: "Active Cronjobs",
  daemonSetCurrent: "Current DaemonSet replicas",
  daemonSetDesired: "Desired DaemonSet replicas",
  applicationReady: "Ready",
  updatedReplicas: "Updated replicas",
  readyReplicas: "Ready replicas",
  availableReplicas: "Available replicas",
  unavailableReplicas: "Unavailable replicas",
  currentReplicas: "Current replicas",
  currentRevision: "Current revision",
  updateRevision: "Update revision",
  collisionCount: "Collision count",

  currentNumberScheduled: "Current number of scheduled",
  numberMisscheduled: "Number of unscheduled",
  desiredNumberScheduled: "Desired number of scheduled",
  numberReady: "Number of ready",
  observedGeneration: "Observed generation",
  updatedNumberScheduled: "Number of updated scheduled",
  numberAvailable: "Number of available",
  numberUnavailable: "Number of unavailable",

  nodeAffinity: "Node affinity",
  podAffinity: "Pod affinity",
  podAntiAffinity: "Pod anti-affinity",
  affinityNodeKey: "Affinity node key",

  cpuOversell: "CPU overcommit percentage",
  memOversell: "Memory overcommit percentage",

  logStartTime: "Log start time",
  prePodLogs: "Previous terminated Pod log",
  tailLines: "lines of recent logs",

  listCpuUsage: "CPU usage",
  listMemoryUsage: "Memory usage",

  minReplicas: "Minimum replicas",
  maxReplicas: "Maximum replicas",

  StatefulSetConf: "StatefulSet advanced setting",
  CronJobConf: "Cronjob advanced setting",
  strategyConf: "Upgrade strategy setting",
  affinityAndTolerationsConf: "Affinity and tolerations setting",
  hostAliasesConf: "Host alias setting",
  containerQuotaConf: "Quota setting",
  portConf: "Port setting"
};
