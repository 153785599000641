export default {
  loginBtn: "登录",
  registerBtn: "注册",
  enterUserName: "请输入用户名",
  enterPassword: "请输入密码",
  enterEmail: "请输入电子邮箱",
  passwordConfirm: "请再次输入密码",
  registerLink: "注册账户",
  loginLink: "已有账户？去登录",
  logout: "退出",
  reLogin: "重新登录",

  toLogin: "立即登录",
  toRegister: "前往注册"
};
