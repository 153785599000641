<template>
  <div>
    <el-button type="primary" size="small" plain @click="data.push({ name: '', value: '' })">
      {{ $t("handle.envAddKeyVal") }}
    </el-button>

    <el-button
      type="primary"
      size="small"
      plain
      @click="data.push({ name: '', valueFrom: { configMapKeyRef: { name: '', key: '' } } })"
    >
      {{ $t("handle.envAddKeyFrom") }}
    </el-button>

    <div class="item-input" v-for="(item, index) in data" :key="index" style="margin-top: 10px;">
      <el-row :gutter="20" v-if="'valueFrom' in item">
        <el-col :span="6">
          <el-input v-model.trim="item.name" size="small">
            <template slot="prepend">
              Name
            </template>
          </el-input>
        </el-col>

        <el-col :span="4">
          <el-select
            v-model="Object.keys(item.valueFrom)[0]"
            style="width: 100%;"
            filterable
            @change="
              value => {
                $set(item, 'valueFrom', {});
                $set(item.valueFrom, value, { name: '', key: '' });
              }
            "
          >
            <el-option label="ConfigMap" value="configMapKeyRef"></el-option>
            <el-option label="Secret" value="secretKeyRef"></el-option>
          </el-select>
        </el-col>

        <el-col :span="6">
          <el-select
            v-model="item.valueFrom[Object.keys(item.valueFrom)[0]].name"
            style="width: 100%;"
            filterable
            @change="
              value => {
                $set(item.valueFrom[Object.keys(item.valueFrom)[0]], 'key', '');
              }
            "
          >
            <div v-if="Object.keys(item.valueFrom)[0] == 'configMapKeyRef'">
              <el-option
                :label="item.metadata.name"
                :value="item.metadata.name"
                v-for="item in configMapList"
                :key="item.metadata.name"
              ></el-option>
            </div>

            <div v-else>
              <el-option
                :label="item.metadata.name"
                :value="item.metadata.name"
                v-for="item in secretList"
                :key="item.metadata.name"
              ></el-option>
            </div>
          </el-select>
        </el-col>

        <el-col :span="6">
          <el-select v-model="item.valueFrom[Object.keys(item.valueFrom)[0]].key" filterable style="width: 100%;">
            <el-option
              :label="key"
              :value="key"
              v-for="key in mapKeys(
                Object.keys(item.valueFrom)[0],
                item.valueFrom[Object.keys(item.valueFrom)[0]].name
              )"
              :key="key"
            ></el-option>
          </el-select>
        </el-col>

        <el-col :span="2">
          <el-button icon="el-icon-delete" circle @click="data.splice(index, 1)"></el-button>
        </el-col>
      </el-row>

      <el-row :gutter="20" v-else>
        <el-col :span="11">
          <el-input v-model.trim="item.name" size="small">
            <template slot="prepend">
              Name
            </template>
          </el-input>
        </el-col>

        <el-col :span="11">
          <el-input v-model.trim="item.value" size="small">
            <template slot="prepend">
              Value
            </template>
          </el-input>
        </el-col>

        <el-col :span="2">
          <el-button icon="el-icon-delete" circle @click="data.splice(index, 1)"></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { configMapListByLocation } from "api/configmap";
import { secretListByLocation } from "api/secret";

import { keys } from "lodash";
export default {
  name: "Env",
  props: {
    data: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },

    namespace: {
      type: String
    },

    locationList: {
      type: Array
    }
  },

  data() {
    return {
      configMapList: [],
      secretList: []
    };
  },

  mounted() {
    this.getConfigMapList();
    this.getSecretList();
  },

  methods: {
    getConfigMapList() {
      if (this.namespace) {
        configMapListByLocation({
          vendor: this.locationList[0],
          region: this.locationList[1],
          cluster: this.locationList[2],
          namespace: this.namespace
        }).then(response => {
          if (response.code === 0) {
            this.configMapList = response.data.items;
          }
        });
      }
    },

    getSecretList() {
      if (this.namespace) {
        secretListByLocation({
          vendor: this.locationList[0],
          region: this.locationList[1],
          cluster: this.locationList[2],
          namespace: this.namespace
        }).then(response => {
          if (response.code === 0) {
            this.secretList = response.data.items;
          }
        });
      }
    },

    mapKeys(type, name) {
      if (type == "configMapKeyRef") {
        for (let item of this.configMapList) {
          if (item.metadata.name == name) {
            return keys(item.data);
          }
        }
      }

      if (type == "secretKeyRef") {
        for (let item of this.secretList) {
          if (item.metadata.name == name) {
            return keys(item.data);
          }
        }
      }
    }
  }
};
</script>
