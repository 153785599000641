export default {
  Cluster: "Clusters",
  nodeName: "Node name",
  nodeList: "Node list",
  clusterKind: "Cluster type",
  clusterResource: "Cluster resource usage",
  nodeResource: "Node resource status",
  hotDeployment: "热点应用",
  clusterTopoTarget: {
    cpuUsagePercent: "CPU usage percent",
    memUsagePercent: "Memory usage percent",
    diskUsagePercent: "Disk usage percent",
    networkReceivePercent: "Network receive percent",
    networkTransmitPercent: "Network transmit percent",
    cpuUsageTotal: "CPU usage in total(core)",
    memWorkingBytes: "Memory usage in total(GiB)"
  },

  JSONSecret: "JSON Secret key",

  kubeletVersion: "Kubelet version",
  osImage: "OS Image",
  kernelVersion: "Kernel version",
  kubeProxyVersion: "KubeProxy version",
  containerRuntimeVersion: "Container runtime",
  architecture: "Computing architecture",

  taints: "Taints",
  taintsDesc: "Taints：taints allow a node to repel a set of pods",
  taintsNoSchedule:
    "NoSchedule(Do not allow new pods to schedule onto the node unless they tolerate the taint, allow all already-running pods to continue running.)",
  taintsPreferNoSchedule: "PreferNoSchedule(The scheduler tries not to schedule new pods onto the node)",
  taintsNoExecute: "NoExecute(Evict any already-running pods that do not tolerate the taint)",

  tolerations: "Tolerations",
  tolerationsDesc: "Tolerations allow (but do not require) the pods to schedule onto nodes with matching taints.",
  belongCluster: "Cluster"
};
