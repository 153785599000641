import http from "../http";

export const roleAdd = data => {
  return http({
    method: "post",
    data,
    url: `/iam.kubestar.io/roles`
  });
};

export const roleEdit = data => {
  return http({
    method: "put",
    data,
    url: `/iam.kubestar.io/roles`
  });
};

export const roleList = (id, params) => {
  return http({
    method: "get",
    params,
    url: `/iam.kubestar.io/organizations/${id}/roles`
  });
};

export const roleDelete = (organization, role) => {
  return http({
    method: "delete",
    url: `/iam.kubestar.io/organizations/${organization}/roles/${role}`
  });
};

export const permissionList = () => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/permissions`
  });
};
