import http from "../http";

export const infra = data => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/infra/metrics`
  });
};

export const clusters = data => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/clusters/metrics`
  });
};

export const metircTarget = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/utilization/metrics`,
    params
  });
};

export const nodeInfra = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/node/infra/metrics`,
    params
  });
};

export const mackerel = params => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/mackerel/hostid/metrics`,
    params
  });
};

export const applicationMetric = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/applications/${data.name}/metrics`,
    params
  });
};

export const podMetric = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/pods/${data.name}/metrics`,
    params
  });
};

export const nodeMetric = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.name}/metrics`,
    params
  });
};

export const monitorAddress = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/address/metrics`,
    params
  });
};

export const namespaceRank = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/metrics`,
    params
  });
};

export const namespaceApplicationRank = data => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/metrics`
  });
};

export const componentStatus = data => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/components/metrics`
  });
};

export const clusterCost = data => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/price/metrics`
  });
};

export const searchGlobal = key => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/search/${key}/metrics`
  });
};

export const promQlQuery = (data, params, headers) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/proxy/metrics`,
    params,
    headers
  });
};

export const resourceDashboard = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/dashboard/metrics`,
    params
  });
};

export const defaultDashboard = (data, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/default/metrics`,
    params
  });
};

export const metricsValue = (data, headers) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/value/metrics`,
    headers
  });
};

export const createPanel = (data, payload) => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/dashboard/metrics`,
    data
  });
};

export const updatePanel = (data, payload) => {
  return http({
    method: "put",
    url: `/monitor.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/dashboard/metrics`,
    data
  });
};

export const batchUpdatePanel = (data, payload) => {
  return http({
    method: "patch",
    url: `/monitor.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/dashboard/metrics`,
    data
  });
};

export const getPanel = data => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/dashboard/${data.id}/metrics`
  });
};

export const deletePanel = data => {
  return http({
    method: "delete",
    url: `/monitor.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/dashboard/${data.id}/metrics`
  });
};
