<template>
  <div>
    <div class="list-title">
      {{ title }}
    </div>

    <div class="search-container">
      <div class="search-input-container">
        <i class="el-icon-search"></i>
        <input v-model="query" @keyup.enter="search" />
      </div>

      <el-tooltip effect="dark" :content="$t('handle.refresh')" placement="bottom-start">
        <el-button plain icon="el-icon-refresh" size="small" @click="refresh"></el-button>
      </el-tooltip>

      <slot name="handle-buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchTool",

  props: {
    title: String
  },

  data() {
    return {
      query: ""
    };
  },

  methods: {
    refresh() {
      this.$emit("refresh");
    },

    search() {
      this.$emit("search", this.query);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.search-container {
  background-color: #f9fbfd;
  padding: 10px;
  @include flex();
}

.search-input-container {
  flex: 1;
  margin-right: 10px;
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 18px;
  @include flex(flex-start);
  padding: 1px 20px;

  & > .el-icon-search {
    margin-right: 8px;
    color: $color-sub;
    flex-basis: 20px;
  }

  & > input {
    flex: 1;
    border: none;
    line-height: 28px;
    width: 100%;
    display: block;
  }
}
</style>
