<template>
  <div>
    <el-dropdown trigger="click" @command="handleSetLanguage" size="medium">
      <span class="el-dropdown-link">
        <svg-icon icon-class="language" class-name="handle-icon"></svg-icon>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :disabled="language === 'en'" command="en">
          <span role="img">🇺🇸</span>
          English
        </el-dropdown-item>

        <el-dropdown-item :disabled="language === 'zh'" command="zh">
          <span role="img">🇨🇳</span>
          中文
        </el-dropdown-item>

        <el-dropdown-item :disabled="language === 'ja'" command="ja">
          <span role="img">🇯🇵</span>
          日本語
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "LanguageSelect",
  computed: {
    language() {
      return this.$store.state.app.language;
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("app/setLanguage", lang);

      window.location.reload();

      //const { fullPath } = this.$route;

      // this.$nextTick(() => {
      //   this.$router.replace({
      //     path: "/redirect" + fullPath
      //   });
      // });
    }
  }
};
</script>
