import router from "./index.js";
import store from "../store/index";
import { getCookies } from "@/utils/cookies";
import { Notification } from "element-ui";
import { isEmpty } from "lodash";
import { Loading } from "element-ui";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login"];

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // 添加最近访问的资源
  if (to.name === "Detail") await store.dispatch("app/setRecordList", to);

  // 获取全局配置，目前只用于MFA
  if (isEmpty(store.state.config.configs)) await store.dispatch("config/setConfigs");

  const hasToken = getCookies("X-KubeStar-Token");

  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/home" });
      NProgress.done();
    } else {
      if (store.state.app.organizationList.length < 1) {
        await store.dispatch("app/getOrganizationList");
      }

      if (isEmpty(store.state.app.userInfo)) {
        try {
          const loading = Loading.service({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            fullscreen: true
          });

          const { role } = await store.dispatch("app/getUserInfo");

          const accessRoutes = await store.dispatch("permission/generateRoutes", role);

          router.addRoutes(accessRoutes);

          loading.close();

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (error) {
          await store.dispatch("app/logout");
          Notification.error({
            title: "Error",
            message: error
          });
          next(`/login`);
          NProgress.done();
        }
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
