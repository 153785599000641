<template>
  <div class="add-form">
    <div class="list-title">
      {{ $t("createOrganization") }}
    </div>
    <organization-form :form="form" ref="form">
      <template #button>
        <el-form-item>
          <el-button type="primary" plain size="small" :loading="loading" @click="submit">
            {{ $t("handle.submit") }}
          </el-button>
        </el-form-item>
      </template>
    </organization-form>
  </div>
</template>

<script>
import { organizationAdd } from "api/organization";
import OrganizationForm from "./Form.vue";
export default {
  components: {
    OrganizationForm
  },

  props: {
    afterSubmit: {
      type: Function,
      default: function() {
        this.$router.push("/admin/organization");
        this.$store.dispatch("app/getOrganizationList");
      }
    }
  },

  data() {
    return {
      form: {
        name: "",
        cnName: "",
        description: "",
        email: "",
        contact: "",
        monitorKey: "",
        monitorValue: ""
      },

      loading: false
    };
  },

  methods: {
    submit() {
      this.$refs["form"].$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          organizationAdd(this.form).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.afterSubmit();
            }
          });
        }
      });
    }
  }
};
</script>

<style></style>
