export default {
  imageSelect: "选择镜像",
  imageSelected: "已选择",
  imageTagName: "标签名称",
  imageConfirm: "您还未选择镜像/镜像标签，确定退出?",
  currentRegistry: "当前镜像仓库: {0}",
  currentImage: "当前镜像: {0}",
  currentImageTag: "当前镜像标签: {0}",
  imageName: "镜像名称",
  imageTag: "镜像标签",
  needAuth: "拉取镜像时，是否需要认证？",
  registry: "镜像仓库",
  ca: "CA证书"
};
