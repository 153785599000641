<template>
  <div style="width: 100%; height: 100%;"></div>
</template>

<script>
import { Chart } from "@antv/g2";
import { bind, clear } from "size-sensor";

export default {
  name: "G2Chart",

  props: {
    drawAction: Function,
    data: Array
  },

  data() {
    return {
      chart: null,
      sensor: null
    };
  },

  methods: {
    initChart() {
      const chart = new Chart({
        container: this.$el,
        autoFit: true,
        padding: "auto",
        height: this.$el.clientHeight
      });

      this.chart = chart;

      // 设置数据
      chart.data(this.data);

      // 回调渲染
      this.drawAction()(chart);
    }
  },

  mounted() {
    this.initChart();
    this.sensor = bind(this.$el, () => {
      // console.log(this.$el);
      this.chart.changeSize(this.$el.clientWidth, this.$el.clientHeight);
      // this.chart.forceFit();
    });
  },

  beforeDestory() {
    this.chart.destroy();
    clear(this.sensor);
    this.chart = null;
    this.sensor = null;
  }
};
</script>
