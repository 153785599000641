<template>
  <div class="user-nav-wrapper">
    <div class="nav-collection-list">
      <div class="nav-panel-title">
        <svg-icon icon-class="star"></svg-icon>
        {{ $t("nav.userStar") }}
      </div>

      <collection style="margin-bottom: 20px;" />

      <div class="nav-panel-title">
        <svg-icon icon-class="tool"></svg-icon>
        {{ $t("KubeStarTools") }}
      </div>

      <div class="tools-list-item">
        <router-link to="/cluster-dashboard">
          {{ $t("tools.clusterMonitor") }}
        </router-link>
      </div>

      <div class="tools-list-item">
        <router-link to="/audit">
          {{ $t("tools.audit") }}
        </router-link>
      </div>

      <div class="tools-list-item">
        <router-link to="/add/import">
          {{ $t("tools.importYaml") }}
        </router-link>
      </div>

      <!-- <div class="tools-list-item">
        <router-link to="/appstore/list">
          应用商店
        </router-link>
      </div> -->
    </div>

    <div class="service-list-wrapper">
      <div class="nav-panel-title">
        User Services
      </div>

      <div class="service-list-container">
        <div class="service-list" v-for="item in list" :key="item.name">
          <div class="service-list-title">
            <svg-icon :icon-class="item.icon" class-name="service-list-icon"></svg-icon>
            {{ item.name }}
          </div>

          <ul>
            <li v-for="list in item.children" :key="list.path" class="service-list-item">
              <router-link :to="list.path"> {{ list.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="showAdminEntry">
        <div class="nav-panel-title">
          Admin Services
        </div>

        <div class="service-list-container">
          <div class="service-list" v-for="item in adminList" :key="item.name">
            <div class="service-list-title">
              <svg-icon :icon-class="item.icon" class-name="service-list-icon"></svg-icon>
              {{ item.name }}
            </div>

            <ul>
              <li v-for="list in item.children" :key="list.path" class="service-list-item">
                <router-link :to="list.path"> {{ list.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/lang";
import { isArray } from "lodash";
import Collection from "@/components/Collection";
export default {
  components: {
    Collection
  },

  data() {
    return {
      list: [
        {
          icon: "Deployment",
          name: i18n.t("application"),
          children: [
            {
              name: i18n.t("Deployment"),
              path: "/list/Deployment"
            },
            {
              name: i18n.t("StatefulSet"),
              path: "/list/StatefulSet"
            },
            {
              name: i18n.t("CronJob"),
              path: "/list/CronJob"
            },
            {
              name: i18n.t("DaemonSet"),
              path: "/list/DaemonSet"
            }
          ]
        },
        {
          icon: "Service",
          name: i18n.t("Service"),
          children: [
            {
              name: i18n.t("Service"),
              path: "/list/Service"
            },
            {
              name: i18n.t("Ingress"),
              path: "/list/Ingress"
            }
          ]
        },
        {
          icon: "Cluster",
          name: i18n.t("Cluster"),
          children: [
            {
              name: i18n.t("Cluster"),
              path: "/list/Cluster"
            }
          ]
        },
        {
          icon: "Namespace",
          name: i18n.t("Namespace"),
          children: [
            {
              name: i18n.t("Namespace"),
              path: "/list/Namespace"
            }
          ]
        },
        {
          icon: "ConfigMap",
          name: i18n.t("ConfigMap"),
          children: [
            {
              name: i18n.t("ConfigMap"),
              path: "/list/ConfigMap"
            },
            {
              name: i18n.t("Secret"),
              path: "/list/Secret"
            }
          ]
        },
        {
          icon: "PersistentVolume",
          name: i18n.t("PersistentVolume"),
          children: [
            {
              name: i18n.t("PersistentVolume"),
              path: "/list/PersistentVolume"
            },
            {
              name: i18n.t("PersistentVolumeClaims"),
              path: "/list/PersistentVolumeClaims"
            }
          ]
        },
        {
          icon: "StorageClass",
          name: i18n.t("StorageClass"),
          children: [
            {
              name: i18n.t("StorageClass"),
              path: "/list/StorageClass"
            }
          ]
        },

        {
          icon: "dashboard",
          name: i18n.t("customMonitor"),
          children: [
            {
              name: i18n.t("customMonitorPanel"),
              path: "/dashboard-custom"
            }
          ]
        }
      ],

      adminList: [
        {
          icon: "role",
          name: i18n.t("iam"),

          children: [
            {
              name: i18n.t("organizationList"),
              path: "/admin/organization"
            },
            {
              name: i18n.t("userGroupList"),
              path: "/admin/group"
            },
            {
              name: i18n.t("roleList"),
              path: "/admin/role"
            },
            {
              name: i18n.t("userList"),
              path: "/admin/user"
            }
          ]
        },

        {
          icon: "Cluster",
          name: i18n.t("nav.clusterManage"),
          children: [
            {
              name: i18n.t("nav.clusterList"),
              path: "/admin/cluster/list"
            },
            {
              name: i18n.t("nav.addCluster"),
              path: "/admin/cluster/add"
            }
          ]
        },

        {
          icon: "Image",
          name: i18n.t("nav.registryManage"),

          children: [
            {
              name: i18n.t("nav.registryList"),
              path: "/admin/registry/list"
            },
            {
              name: i18n.t("nav.addRegistry"),
              path: "/admin/registry/add"
            }
          ]
        },

        {
          icon: "alarm",
          name: i18n.t("alarm"),

          children: [
            {
              name: i18n.t("alarmBasic"),
              path: "/admin/alarm/config"
            },

            {
              name: i18n.t("alarmReceiver"),
              path: "/admin/alarm/receiver"
            },

            {
              name: i18n.t("alarmSilence"),
              path: "/admin/alarm/silence"
            }
          ]
        }
      ]
    };
  },

  computed: {
    showAdminEntry() {
      let adminEntry = false;
      let { sysAdminFlag, organizations } = this.$store.state.app.userInfo;
      if (sysAdminFlag) {
        adminEntry = true;
      } else {
        if (isArray(organizations) && organizations.length > 0) {
          for (let item of organizations) {
            if (item.uuid === this.$store.state.app.organization) {
              if (item.adminFlag) adminEntry = true;
            }
          }
        }
      }

      return adminEntry;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.user-nav-wrapper {
  overflow: hidden;

  .nav-collection-list {
    padding: 20px 30px;
    width: 25%;
    box-sizing: border-box;
    float: left;

    .el-icon-star-on {
      color: #ff9900;
      font-size: 20px;
      margin-right: 4px;
    }
  }
}

.service-list-wrapper {
  padding: 20px 30px;
  width: 75%;
  float: left;
  box-sizing: border-box;
  overflow: hidden;
  border-left: 1px solid #545b64;

  .service-list-container {
    columns: 3;
  }

  .service-list {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;

    .service-list-title {
      @include flex(flex-start);
      color: #fff;
      font-size: 14px;
      margin-bottom: 6px;

      .service-list-icon {
        width: 20px;
        height: 20px;
      }
    }

    .service-list-item {
      text-indent: 22px;
      & > a {
        color: $color-sub;
        line-height: 1.8;
        margin-bottom: 4px;
      }
    }
  }
}

.tools-list-item {
  text-indent: 22px;
  margin-bottom: 10px;

  a {
    color: #fff;
  }
}
</style>
