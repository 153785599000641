export default {
  Cluster: "集群",
  nodeName: "节点名称",
  nodeList: "节点列表",
  clusterKind: "集群类型",
  clusterResource: "集群资源情况",
  nodeResource: "节点资源状态",
  hotDeployment: "热点应用",
  clusterTopoTarget: {
    cpuUsagePercent: "CPU使用率",
    memUsagePercent: "内存使用率",
    diskUsagePercent: "磁盘使用率",
    networkReceivePercent: "网络上行宽带使用量",
    networkTransmitPercent: "网络下行宽带使用量",
    cpuUsageTotal: "CPU使用量(core)",
    memWorkingBytes: "内存使用量(GiB)"
  },

  JSONSecret: "JSON 密钥",

  kubeletVersion: "kubelet版本",
  osImage: "操作系统镜像",
  kernelVersion: "内核版本",
  kubeProxyVersion: "kubeProxy版本",
  containerRuntimeVersion: "容器运行时",
  architecture: "计算架构",

  taints: "污点管理",
  taintsDesc: "污点：使节点能够排斥一类特定的 Pod",
  taintsNoSchedule: "NoSchedule(不允许调度，已调度的资源不受影响)",
  taintsPreferNoSchedule: "PreferNoSchedule(尽量不调度)",
  taintsNoExecute: "NoExecute(不允许调度)",

  tolerations: "容忍",
  tolerationsDesc: "容忍应用于 Pod 上的，允许（但并不要求）Pod 调度到带有与之匹配的污点的节点上",
  belongCluster: "所属集群"
};
