export default {
  Service: "Services",
  serviceName: "Service name",
  serviceConf: "Service configuration",
  serviceType: "Service type",
  clusterIP: "Internal service",
  LoadBalancer: "External service",
  targetPort: "Container port",
  servicePort: "Service port",
  nodePort: "Node port",
  ServiceTypeDesc: {
    ClusterIP: "no external access",
    LoadBalancer: "service accessed through load balancer of cloud provider",
    NodePort: "service accessed through corresponding cluster node port"
  },

  useIngress: "Enable Ingress",
  portInfo: "Port information"
};
