export default {
  auditList: "审计列表",
  auditUserName: "用户名",
  auditResource: "请求资源",
  auditReqMethod: "请求方法",
  auditHandleTime: "操作时间",
  auditReqURI: "请求地址",
  auditRespStatusCode: "请求状态码",
  auditBusinessStatusCode: "业务状态码",
  auditBusinessMessage: "业务信息",
  auditSearchList: "搜索条件",
  auditReqHeader: "请求头",
  auditReqBody: "请求体"
};
