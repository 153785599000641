<template>
  <el-form ref="form" :model="form" :rules="rules" size="small">
    <el-form-item :label="$t('name')" prop="name">
      <el-input v-model="form.name" :disabled="!editable"></el-input>
    </el-form-item>

    <el-form-item :label="$t('organizationAlias')" prop="cnName">
      <el-input v-model="form.cnName"></el-input>
    </el-form-item>

    <el-form-item :label="$t('contacter')" prop="contact">
      <el-input v-model="form.contact"></el-input>
    </el-form-item>

    <el-form-item :label="$t('contacterEmail')" prop="email">
      <el-input v-model="form.email"></el-input>
    </el-form-item>

    <el-form-item :label="$t('description')" prop="description">
      <el-input v-model="form.description"></el-input>
    </el-form-item>

    <slot name="button"></slot>
  </el-form>
</template>

<script>
export default {
  props: {
    form: Object,
    editable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      rules: {
        name: [{ required: true, message: this.$t("rules.require"), trigger: "blur" }],

        cnName: { required: true, message: this.$t("rules.require"), trigger: "blur" },
        // description: { required: true, message: this.$t("rules.require"), trigger: "blur" },
        email: [
          { type: "email", message: this.$t("rules.pattern"), trigger: ["blur", "change"] },
          { required: true, message: this.$t("rules.require"), trigger: "blur" }
        ],
        contact: { required: true, message: this.$t("rules.require"), trigger: "blur" }
      }
    };
  }
};
</script>
