import Vue from "vue";
import VueI18n from "vue-i18n";
import ja from "./ja";
import zh from "./zh";
import en from "./en";

import elementJaLocale from "element-ui/lib/locale/lang/ja";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import Cookies from "js-cookie";

Vue.use(VueI18n);

const messages = {
  ja: {
    ...ja,
    ...elementJaLocale
  },

  zh: {
    // ...zh,
    ...elementZhLocale,
    ...zh
  },

  en: {
    ...elementEnLocale,
    ...en
  }
};

const i18n = new VueI18n({
  locale: getLanguage(),
  messages
});

export function getLanguage() {
  const chooseLanguage = Cookies.get("language");
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  const language = (window.navigator["language"] || window.navigator["browserLanguage"]).toLowerCase();

  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return "zh";
}

export default i18n;
