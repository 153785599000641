export default {
  ConfigMap: "Configmaps",
  configMapName: "Configmap name",
  configMapRootPath: "Configmap root path",
  mountPath: "Mount path",
  configMapItem: "Configmap item",
  mountContainer: "Mount container: ",
  mountFile: "Mount file",
  overlay: "Overlay",
  mode: "ReadWrite mode"
};
