export default {
  tips: {
    nameCommonPattern: "Required, lowercase letters/numbers/dashes are allowed",
    application: "Application name, required, lowercase letters/numbers/dashes are allowed",
    replicas: "Application instance replicas",
    terminationGracePeriodSeconds: "The application will be terminated if exceeds this terminationGracePeriodSeconds",
    podManagementPolicy: "Pod creating and scaling policy",
    updateStrategy: "Update Strategy",
    partition: "Partition implies updating scope when updateStrategy is RollingUpdate",
    schedule: "Same with Linux Crontab rules, e.g. (execute at 2am every day: 0 2 * * *)",
    concurrencyPolicy:
      "ConcurrentPolicy implies whether the next scheduled task should be started when previous task has not been completed",
    startingDeadlineSeconds: "Task still can be scheduled before StartingDeadlineSeconds elapsed",
    suspend: "Suspended if status is “ON”, then Cronjob task will be suspended executing",
    activeDeadlineSeconds:
      "Task will be killed if active executing time exceed ActiveDeadlineSeconds in case of spending too much time on this job",
    backoffLimit: "The times limit when a Pod is under backoff retrying",
    startCommand: "The entrypoint command when application starting, arguments always needed",
    postStart:
      "The action executed right after application started, which can be shell script, HTTP or Socket port probe, usually be used to register service",
    preStop:
      "The action, executed right after application stopped ,which can be shell script, HTTP or Socket port probe, usually be used to unregister service",
    livenessProbe:
      "Check application health status, the action can be shell script, HTTP or Socket port probe, which usually detect application load, the application will be restarted if multiple check failure",

    readinessProbe:
      "Check application ready status, the action can be shell script, HTTP or Socket port probe, which usually detect if application is ready to receive request, the traffic won’t be proxied to this instance if multiple check failure",

    parallelism: "The maximum concurrent Pods for a given task at a given time",
    completions: "The number of completed Pods for given task",
    addlabels: "Label format: Key:Val",
    imageText: "This image is not found in the repositories, please fill it up manually",
    selectExistImage: "Select image in current image registry ",
    labelSearch: "The search pattern is Key:Val if you search something by labels",
    addContainerPorts: "Container port type",
    location: "Select cluster",
    maxUnavailable: "The maximum number of unavailable pod could be tolerated when rolling updated",
    maxSurge: "The maximum number of extra pod when rolling updated",
    checkoutNamespace: "Container configuration will be filled in again if switch namespace",
    containerConfTooltipText: "Please sumbit or delete current editing container configuration ",
    serviceConfTooltipText: "Please sumbit or delete current editing service configuration",
    containerConfigMap: "Use a ConfigMap for setting configuration data separately from application code",
    containerSecret:
      "Kubernetes Secrets let you store and manage sensitive information, such as passwords, OAuth tokens, and ssh keys",
    containerVolume:
      "On-disk files in a container are ephemeral, which presents some problems for non-trivial applications when running in containers. One problem is the loss of files when a container crashes. The kubelet restarts the container but with a clean state. A second problem occurs when sharing files between containers running together in a Pod. The Kubernetes volume abstraction solves both of these problems",
    containerPersistentVolume:
      "A PersistentVolume (PV) is a piece of storage in the cluster that has been provisioned by an administrator or dynamically provisioned using Storage Classes. It is a resource in the cluster just like a node is a cluster resource. PVs are volume plugins like Volumes, but have a lifecycle independent of any individual Pod that uses the PV",
    containerLiftCycleTitle: "Define a Command and Arguments for a Container",
    containerLiftCycle:
      "When you create a Pod, you can define a command and arguments for the containers that run in the Pod, The command and arguments that you define cannot be changed after the Pod is created",
    containerHealthCheckTitle: "Configure Liveness, Readiness and Startup Probes"
  },

  empty: "No relevant resources found",
  emptyParams: "No {0} monitoring data",
  emptyInLocation: "No related resource of【{0}】",
  emptyResource: "No record of {0}",
  success: "Success",
  error: "Error",

  step: "Steps",
  addClusterStep: {
    1: "Choose cloud provider",
    2: "Fill in configuration and test cluster connection",
    3: "Fill in monitoring configuration",
    4: "Complete"
  },

  addRegistryStep: {
    1: "Choose image registry",
    2: "Fill in configuration and test image registry connection",
    3: "Complete"
  },

  applicationListBatchStep: {
    1: "Please confirm selected application",
    2: "Confirm"
  },

  registryTips: {
    needAuth: "ImagePullSecret will be created automatically when this is enable",
    test: "Test the connection between KubeStar and image registry"
  },

  clusterTips: {
    test: "Test the connection between KubeStar and cluster",
    monitor: "Add monitor client for cluster status visualization"
  },

  moreConf: "More configurations",

  imageTips: "Image format：image registry/image name:tag",

  ingressTips: {
    ingressItemAdd: "Add services for domain names"
  },

  recommand: "Recommended resources based on history",

  addApplication: {
    import: "Unused data detected, do you want to reload them?",
    leavePrompt: "You have unsaved data on this page, are you sure to exit now ?"
  },

  configMapTips: {
    mountPath: "Mount config to the specificed mountPath",
    overlay: "If mounted config files overwrite files existed in this path",
    mode: "The permission of config files mounted "
  },

  receiverTips: {
    wechatCorpID: "Wechat corp account ID, find it in 'My Corps'",
    wechatAgentID: "Third-party corp application ID, find it in ‘My third-party application'",
    wechatApiSecret: "Third-party application access key, find it in 'My third-party application'"
  },

  alarmTips: {
    duration: "Alerts will be triggered if monitoring metrics have exceeds threshold for this duration",
    alarmMessageContent: "Using $value to get current metrics"
  },

  affinityTips: {
    nodeAffinity: "Desire Pod to be scheduled on which node",
    podAffinity: "Desire Pod to be scheduled with which Pod",
    podAntiAffinity: "Desire Pod not to be scheduled with which Pod on which node",
    nodeKey: "Node label key which running Pod and its affinity Pod",
    antiNodeKey: "Node label key which running Pod and anti-affinity Pod"
  },

  imageHostTips: {
    SWRHost: "Host format: swr.{projectName}.myhuaweicloud.com",
    TCRHost: "Host format: {registryName}.tencentcloudcr.com"
  },

  listSelectResourceTips: "Please select resource details to view",
  listHasSelectedApplications: "Selected application",
  searchLabelKey: "Search label",

  timeSelectErrorTips: "Start time couldn't later than end time",
  editAnnotationsTips:
    "Annotation/Label modification will trigger Deployment, Statefulset, Daemonset, Cronjob, Pod restart, but node status no influence",

  createClusterTips:
    "After the cluster is created successfully, which is available by binding user or user groups as well as getting permissions.",
  monitorClusterDeny: "Cluster {0} is not bind by current user or already forbidden",
  copyInvitationInfo: "Organization invitation information has been copied to the pasteboard",
  generateInvitationInfoTips: "Organization invitation information cloud be generated at organization list",
  emptyUser: "Please [create user], there is no user under organization",
  emptyCluster: "Please [create cluster], there is no cluster under organization",
  emptyUserGroup: "Please bind user group or not, there is no user group",
  emptyUserGroupTips:
    "User will get user group permission firstly, if no user group belonging, user will inherit their own permission",
  emptyOrganization: "No organization binding",
  emptyCategory: "No relevent category, [quick creation]",

  whatIsAKSK: "What's AK/SK",
  AKSKTips: "using AK(Access KeyID), SK(Secret Access Key) for signing requests in command line and OpenAPI access",

  importPanelTips1: "1.Batch importing may change cluster information",
  importPanelTips2: "2.Batch importing won't delete already existed panel",

  createClusterSuccessTips:
    "After the cluster is created successfully, which is available by binding user or user groups as well as getting permissions"
};
