import http from "../http";

export const groupAdd = data => {
  return http({
    method: "post",
    data,
    url: `/iam.kubestar.io/groups`
  });
};

export const groupEdit = data => {
  return http({
    method: "put",
    data,
    url: `/iam.kubestar.io/groups`
  });
};

export const groupList = (id, params) => {
  return http({
    method: "get",
    params,
    url: `/iam.kubestar.io/organizations/${id}/groups`
  });
};

export const groupDelete = (organization, group) => {
  return http({
    method: "delete",
    url: `/iam.kubestar.io/organizations/${organization}/groups/${group}`
  });
};

export const groupDetail = (organization, group) => {
  return http({
    method: "get",
    url: `/iam.kubestar.io/organizations/${organization}/groups/${group}`
  });
};
