export default {
  loginBtn: "Login",
  registerBtn: "Register",
  enterUserName: "Please enter username",
  enterPassword: "Please enter password",
  enterEmail: "Please enter email",
  passwordConfirm: "Please confirm password",
  registerLink: "Register",
  loginLink: "Login",
  logout: "Logout",
  reLogin: "Re-Login",

  toLogin: "Login",
  toRegister: "Register"
};
