export default {
  Service: "服务",
  serviceName: "服务名称",
  serviceConf: "服务配置",
  serviceType: "服务类型",
  clusterIP: "内部服务",
  LoadBalancer: "外部服务",
  targetPort: "容器端口",
  servicePort: "服务端口",
  nodePort: "节点端口",
  ServiceTypeDesc: {
    ClusterIP: "不提供外网访问",
    LoadBalancer: "通过云服务商提供的负载均衡器来访问服务",
    NodePort: "通过访问集群节点的对应端口来访问服务"
  },

  useIngress: "启用Ingress",
  portInfo: "端口信息"
};
