export default {
  rules: {
    require: "必須",
    pattern: "フォーマットが不一致ですので再入力してください。",
    configMapFileName: "コンフィグのFileNameが既に存在しています。",
    location: "位置とネームスペースを選択してください",
    existed: "{0}が既に存在しています。",

    login: {
      passwordStrength: "パスワード強度",
      password: "必ず、小文字、数字を含め、6桁以上のパスワードを設定してください",
      passwordConfirm: "パスワード再確認",
      passwordDiff: "入力したパスワードが不一致です",
      email: "正しいメールアドレスを入力してください",
      userName:
        "ユーザ名称の頭文字は、必ず英文小文字にし、且つ、英数字、アンダーバー、ハイフン、および、ドッドのみを入力可能です"
    }
  }
};
