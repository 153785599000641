export default {
  rules: {
    require: "Required",
    pattern: "Incorrect format，please re-enter",
    configMapFileName: "No matter same configuration or not, FileName is not allowed to repeat",
    location: "Please choose location and namespace",
    existed: "{0} already existed，please change",

    login: {
      passwordStrength: "Password strength",
      password: "At least 6 digits in length as well as number and lowercase letters are required",
      passwordConfirm: "Please confirm password",
      passwordDiff: "Unmatched password",
      email: "Please enter correct email",
      userName:
        "The first letter of user name is lowercase, and just only alphanumeric characters, _, - and .are available"
    }
  }
};
