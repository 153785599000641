export default {
  handle: {
    submit: "確定",
    cancel: "キャンセル",
    handle: "操作",
    edit: "編集",
    delete: "削除",
    add: "追加",
    create: "作成",
    save: "保存",
    publish: "提出",
    search: "検索",
    submitAgain: "再提出",
    backLast: "前ページへ",
    backHome: "ホームへ",
    clusterTest: "クラスタ接続テスト",
    resume: "回復",
    pause: "一時停止",
    registryTest: "レジストリ接続テスト",
    addCluster: "クラスタ追加",
    addRegistry: "レジストリ追加",
    restart: "リセット",
    update: "アップデータ",
    rollback: "ロールバック",
    hotfix: "ホットフィックス",
    scale: "スケール",
    collect: "お気に入り",
    cancelCollect: "解除",
    permission: "権限設定",
    addNodePort: "ノートポート追加",
    addService: "サービス追加",
    toRollback: "該当バージョンへロールパック",
    toYAML: "YAML比較の生成",
    test: "テスト",
    testing: "テスト中",
    lastStep: "前へ",
    nextStep: "次へ",
    envAddKeyVal: "環境変数追加",
    envAddKeyFrom: "設定ファイル追加/秘密鍵流用",
    nodeResume: "ノート再開",
    nodePause: "ノート停止",
    editMonitor: "監視情報設定",
    refresh: "更新",
    moreHandle: "最も操作",
    import: "インポート",
    cancelImport: "インポートを取消",
    toLogs: "ログ確認",
    toTerminal: "ターミナル確認",
    aboutUs: "システムについて",
    quickCreate: "「迅速追加」",
    configMapAddItem: "設定項マウントの追加",
    addHostAlias: "ホストエイリアス追加",
    addAlias: "エイリアス追加",
    upload: "ファイルアップデータ",
    input: "値の記入",
    openRefresh: "自動更新ON ",
    closeRefresh: "自動更新OFF",
    addAnnotation: "備考追加",

    addReceiver: "宛先の追加",
    addEmailConf: "メール設定の追加",
    addSlackConf: "Slack設定の追加",
    addWeChatConf: "WeChat設定の追加",
    addPhoneConf: "電話/SMS設定の追加",
    addPhoneNumber: "電話番号の追加",
    moreConf: "もっと見る",
    slideUpConf: "設定を畳む",
    addSilenceConf: "デフォルト値を追加",
    reCreate: "再作成",
    addSilenceLabel: "サイレンスラベルを追加",
    addAlarmRules: "アラームルールを追加",

    addNodeLabels: "ターゲットNodeラベルを追加",
    addPodAffinityLabels: "親和Pod情報を追加",
    addPodAntiAffinityLabels: "非親和Pod情報を追加",

    searchService: "検索サービス",
    podSummary: "Pod概要",
    addToleration: "Add tolerations"
  }
};
