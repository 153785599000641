<template>
  <div class="type-list-select-row">
    <div class="select-container">
      <el-select
        v-model="render"
        style="width: 100%;"
        filterable
        @change="change"
        :multiple="multiple"
        v-if="type == 'namespace'"
        size="small"
        :loading="loading"
      >
        <template #prefix v-if="loading">
          <i class="el-icon-loading" style="margin-left: 4px;"></i>
        </template>
        <el-option
          :label="`Namespace: ${item.metadata.name}`"
          :value="item.metadata.name"
          :key="item.metadata.name"
          v-for="item in list"
          :disabled="item.status.phase != 'Active'"
        >
          <div class="namespace-option-list">
            <div>{{ item.metadata.name }}</div>
            <div>
              <div class="status success" v-if="item.status.phase == 'Active'">Active</div>
              <div class="status danger" v-else>{{ item.status.phase }}</div>
            </div>
          </div>
        </el-option>
      </el-select>
    </div>

    <el-button icon="el-icon-refresh" style="margin-left: 4px;" @click="initList"></el-button>
  </div>
</template>

<script>
import { namespaceListByLocation } from "api/namespace";

export default {
  props: {
    data: {
      type: String
    },

    type: {
      type: String
    },

    multiple: {
      type: Boolean,
      default: false
    },

    locationList: {
      type: Array
    }
  },

  name: "TypeList",

  watch: {
    locationList: {
      handler: function() {
        this.initList();
      },

      deep: true
    }
  },

  data() {
    return {
      list: [],
      loading: false
    };
  },

  computed: {
    render: {
      get() {
        let value = "";

        if (this.type == "namespace") {
          value = this.multiple ? [this.data] : this.data;
        }

        return value;
      },

      set() {}
    }
  },

  methods: {
    change(val) {
      if (this.type == "namespace") {
        this.$emit("update:data", val);
        this.$emit("change");
      }
    },

    initList() {
      if (this.type == "namespace") {
        if (this.locationList.length > 0) {
          this.loading = true;
          namespaceListByLocation({
            vendor: this.locationList[0],
            region: this.locationList[1],
            cluster: this.locationList[2]
          }).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.list = response.data.items;
            }
          });
        } else {
          this.list = [];
        }
      }
    }
  },

  mounted() {
    if (this.locationList.length > 0) this.initList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";

.type-list-select-row {
  @include flex(flex-start);

  .select-container {
    flex: 1;
  }
}

.namespace-option-list {
  @include flex();
}
</style>
