<template>
  <div class="app-wrapper">
    <router-view />
    <control class="main-control" v-if="!showControl" />
  </div>
</template>

<script>
import Control from "@/layout/components/Control.vue";

export default {
  components: {
    Control
  },

  computed: {
    showControl() {
      return ["Login", "Home"].includes(this.$route.name);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  width: 100%;
}

.main-control {
  position: fixed;
  right: 20px;
  bottom: 30px;
  cursor: pointer;
  z-index: 99;
}
</style>
