<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :href="iconName" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    iconName() {
      let iconClass = "";

      switch (this.iconClass) {
        case "Cluster":
          iconClass = "cluster";
          break;

        case "ConfigMap":
          iconClass = "configMap";
          break;

        case "Container":
          iconClass = "container";
          break;

        case "CronJob":
          iconClass = "cronJob";
          break;

        case "DaemonSet":
          iconClass = "daemonSet";
          break;

        case "Deployment":
          iconClass = "deployment";
          break;

        case "Image":
          iconClass = "image";
          break;

        case "Ingress":
          iconClass = "ingress";
          break;

        case "Namespace":
          iconClass = "namespace";
          break;

        case "Node":
          iconClass = "node";
          break;

        case "PersistentVolume":
          iconClass = "persistentVolume";
          break;

        case "PersistentVolumeClaims":
          iconClass = "persistentVolumeClaims";
          break;

        case "Pod":
          iconClass = "pod";
          break;

        case "Secret":
          iconClass = "secret";
          break;

        case "Service":
          iconClass = "service";
          break;

        case "StatefulSet":
          iconClass = "statefulSet";
          break;

        case "StorageClass":
          iconClass = "storageClass";
          break;

        default:
          iconClass = this.iconClass;
          break;
      }

      return `#icon-${iconClass}`;
    },

    svgClass() {
      if (this.className) {
        return "svg-icon " + this.className;
      } else {
        return "svg-icon";
      }
    }
  }
};
</script>

<style scoped>
.svg-icon {
  height: 30px;
  width: 30px;
  margin-right: 4px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
