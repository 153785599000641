import http from "../http";

export const clusterList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/clusters`
  });
};

export const nodeList = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes`
  });
};

export const clusterDetail = data => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}`
  });
};

export const nodeDetail = data => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.node}`
  });
};

export const clusterPods = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/pods`
  });
};

export const nodePods = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.node}/pods`
  });
};

export const nodeEvent = data => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.node}/events`
  });
};

export const nodeSchedulable = (data, params) => {
  return http({
    method: "put",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/nodes/${data.node}`
  });
};

export const nodeUpdate = (payload, data) => {
  return http({
    method: "put",
    data,
    url: `/k8s.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/nodes/${payload.node}`
  });
};
