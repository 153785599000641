export default {
  monitor: "モニタリング",
  monitorType: "モニタリング種類",
  monitorReady: "使用",
  monitorHost: "ホスト",
  clusterMonitor: "クラスタのモニタリング",
  namespaceMonitor: "ネームスペースのモニタリング",
  currentMonthCost: "今月のコスト概算",
  previousMonthCost: "前月のコスト概算",
  masterComponentsStatus: "Masterコンポネントステータス",
  nodeStatus: "Nodeステータス",
  podSummary: "Pod概要",
  kubernetesSummary: "Kubernetesリリース概要",
  namespaceRank: "ネームスペース使用量ランク",
  deploymentRank: "デプロイメント使用量ランク",
  statefulSetRank: "処理状態セット使用量ランク",
  cronJobRank: "CronJob 使用量ランク",
  daemonSetRank: "DaemonSet 使用量ランク",
  rankByCPU: "CPUランク",
  rankByMemory: "メモリランク",
  costDashboard: "コストダッシュボード",

  clusterMonitorDesc: "クラスタに多次元のモニタリングを提供",
  costDashboardDesc: "コスト分析",
  auditDesc: "オペレーションログを確認"
};
