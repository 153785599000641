export default {
  application: "应用",
  Deployment: "无状态应用",
  StatefulSet: "有状态应用",
  CronJob: "定时任务",
  DaemonSet: "守护进程集",
  applicationTopo: "应用拓扑图",

  applicationSteps: {
    first: "基本设置",
    second: "容器组配置",
    third: "服务配置"
  },

  applicationName: "应用名称",
  applicationType: "应用类型",

  replicas: "副本数量",
  terminationGracePeriodSeconds: "停止等待时间",
  podManagementPolicy: "容器组扩展策略",
  OrderedReady: "依次创建或删除",
  Parallel: "并行创建或删除",
  strategy: "升级策略",
  updateStrategy: "滚动升级策略",
  RollingUpdate: "滚动升级",
  OnDelete: "当Pod被删除时触发",
  partition: "分片",
  volumeClaimTemplates: "存储卷申请模版",
  volumeClaimTemplatesName: "模版名称",
  schedule: "调度规则",
  concurrencyPolicy: "并发策略",
  startingDeadlineSeconds: "启动期限",
  suspend: "暂停状态",
  activeDeadlineSeconds: "单任务最大运行时间",
  backoffLimit: "重试次数",
  parallelism: "单任务Pod平行数",
  completions: "单任务期望Pod完成数",

  containerAdd: "新增容器",
  containerName: "容器名称",
  containerConf: "容器组配置",
  containerStatus: "容器状态",

  nodePort: "开放端口",
  env: "环境变量",
  liftCycle: "生命周期",
  startCommand: "启动命令",
  runningParams: "运行参数",
  runningCommand: "运行命令",
  postStart: "启动后处理",
  preStop: "停止前处理",
  handleType: "处理方式",
  command: "执行命令",
  exec: "命令行方式",
  httpRequest: "HTTP请求方式",
  tcp: "TCP请求方式",
  health: "健康检查",
  checkType: "检查方式",
  livenessProbe: "应用存活探针",
  initialDelaySeconds: "首次探测时间/秒",
  periodSeconds: "探测间隔时间/秒",
  timeoutSeconds: "探测超时时间/秒",
  readinessProbe: "应用就绪探针",
  successfulJobsHistoryLimit: "成功任务最大保存数",
  failedJobsHistoryLimit: "失败任务最大保存数",

  podName: "Pod名称",
  podIP: "实例节点",
  hostIP: "计算节点",
  restartTimes: "重启次数",
  logs: "应用日志",
  terminal: "终端",
  pod: "POD",
  container: "容器",

  scaleByHand: "手动扩容",
  autoScale: "自动扩容",
  autoScaleSwitch: "自动扩容开关",
  autoScaleResource: "资源纬度",
  cpuThreshold: "CPU阈值",
  memoryThreshold: "内存阈值",
  replicasRange: "实例范围",

  podStatus: {
    Initialized: "初始化",
    Ready: "应用实例就绪",
    ContainersReady: "容器就绪",
    PodScheduled: "应用实例已调度"
  },

  hostAliases: "HOST别名",
  alias: "别名列表",

  lastSchedule: "上次调度时间",
  nextSchedule: "下次调度时间",
  activeCronJob: "当前活跃任务",
  daemonSetCurrent: "当前实例数",
  daemonSetDesired: "预期实例数",
  applicationReady: "就绪",
  updatedReplicas: "已升级实例",
  readyReplicas: "就绪实例",
  availableReplicas: "可用实例",
  unavailableReplicas: "不可用实例",
  currentReplicas: "当前实例",
  currentRevision: "当前版本",
  updateRevision: "升级版本",
  collisionCount: "碰撞次数",

  currentNumberScheduled: "当前已调度实例",
  numberMisscheduled: "未调度实例",
  desiredNumberScheduled: "期望调度实例",
  numberReady: "已就绪实例",
  observedGeneration: "最新版本号",
  updatedNumberScheduled: "已更新的实例数",
  numberAvailable: "可用实例数",
  numberUnavailable: "不可用实例数",

  nodeAffinity: "节点亲和性调度",
  podAffinity: "POD亲和性调度",
  podAntiAffinity: "POD反亲和性调度",
  affinityNodeKey: "节点标签Key",

  cpuOversell: "CPU超卖比",
  memOversell: "内存超卖比",

  logStartTime: "日志起始时间",
  prePodLogs: "返回上一个终止运行容器的日志",
  tailLines: "查看最近日志条数",

  listCpuUsage: "CPU资源用量",
  listMemoryUsage: "内存资源用量",

  minReplicas: "最小副本数",
  maxReplicas: "最大副本数",

  StatefulSetConf: "有状态应用高级设置",
  CronJobConf: "定时任务高级设置",
  strategyConf: "升级策略设置",
  affinityAndTolerationsConf: "亲和性与容忍设置",
  hostAliasesConf: "Host别名设置",
  containerQuotaConf: "配额设置",
  portConf: "端口设置"
};
