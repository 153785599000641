<template>
  <div>
    <div class="empty-container" v-if="type == 'empty'">
      <img :src="empty" class="result-img" />
      <div class="desc">{{ $t("empty") }}</div>
    </div>

    <div class="empty-container" v-else>
      <img :src="success" class="result-img" />
      <div class="desc">{{ $t("success") }}</div>

      <el-button type="primary" plain size="small" @click="publishAgain">{{ $t("handle.submitAgain") }}</el-button>
      <el-button size="small" @click="backToHome">{{ $t("handle.backHome") }}</el-button>
    </div>
  </div>
</template>

<script>
import empty from "@/assets/illustration/empty.svg";
import success from "@/assets/illustration/success.svg";
export default {
  props: {
    type: String
  },

  data() {
    return {
      empty,
      success
    };
  },

  methods: {
    publishAgain() {
      const { fullPath } = this.$route;
      this.$nextTick(() => {
        this.$router.replace({
          path: "/redirect" + fullPath
        });
      });
    },

    backToHome() {
      this.$router.replace({
        path: "/home"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.empty-container {
  margin: 0 auto;
  text-align: center;
}

.result-img {
  width: 120px;
  height: 120px;
}

.desc {
  color: $color-sub;
  font-size: 12px;
  margin: $grid-height 0;
}
</style>
