export default {
  tips: {
    nameCommonPattern: "小文字の英数字、ハイフンで入力",
    application: "アプリケーションの名称が必須。（小文字英数字、ハイフン）",
    replicas: "アプリのレプリカ数",
    terminationGracePeriodSeconds: "アプリ終了の待ち時間（設定時間を超えると強制的に終了させます）。",
    podManagementPolicy: "PODの作成、およびスケールのポリシー",
    updateStrategy: "ローリングアップデートポリシー",
    partition:
      "ポリシーが「ローリングアップデータ」と指定する時に、パーティションの設定、および、アップデータの段階の設定が必要",
    schedule: "crontab規則に従う。例(毎日2点実行: 0 2 * * *)",
    concurrencyPolicy:
      "並行処理方式とは前のジョブ完成してない、且つ、次のジョブ時間になった場合、ジョブが正常に起動するか",
    startingDeadlineSeconds: "調整が間に合わない場合、調整時間何秒以内はまだ調整可能です",
    suspend: "運行停止するかどうか、もし状態がONとするなら、タイミングタスクは実行を一時停止します。",
    activeDeadlineSeconds:
      "ジョブの最大継続実行時間。PODが長時間実行を防止するため、この時間を超えると、PODが消されます。",
    backoffLimit: "POD起動失敗時のリトライ回数",
    startCommand: "実行する起動コマンド（通常実行パラメータの提供として使用されます）",
    postStart:
      "起動した後、実施する動きです。スクリプトコマンド、HTTPのURL、および、Socketのポートなどを設定可能です。（通常サービスのレジストとして使用されます）",
    preStop:
      " 終了する前に実施する動きです。スクリプトコマンド、HTTPのURL、および、Socketのポートなどを設定可能です。（通常サービスのアンレジストとして使用されます）",
    livenessProbe:
      "　アプリケーションのヘルスチェックの設定です。スクリプトコマンド、HTTPのURL、および、Socketのポートなどを設定可能です。（通常的にアプリケーションの負荷をチェックしますが、連続でチェック失敗したら、アプリケーションが再起動します）",

    readinessProbe:
      " デプロイメントの状況チェックです。スクリプトコマンド、HTTPのURL、および、Socketのポートなどを設定可能です。（通常的に、アプリケーションがリクエストを受けるかをチェックしますが、連続でチェック失敗したら、リクエストを該当アプリケーションに送信しません）",

    parallelism: "該当ジョブの指定時間のPOD最大並行数",
    completions: "該当ジョブを完成するため必要なPOD数",
    addlabels: "ラベルのフォーマット Key:Val",
    imageText: "該当イメージファイルは見つかりませんでしたため、入力してください。",
    labelSearch: "ラベルで検索する際に、検索条件の入力フォーマット Key:Val ",
    addContainerPorts: "追加するポートタイプを選択",
    containerConfigMap: "アプリケーションのコードとは別に設定データを設定するには、ConfigMapを使用します。",
    containerSecret:
      "KubernetesのSecretはパスワード、OAuthトークン、SSHキーのような機密情報を保存し、管理できるようにします",
    containerVolume:
      "On-disk files in a container are ephemeral, which presents some problems for non-trivial applications when running in containers. One problem is the loss of files when a container crashes. The kubelet restarts the container but with a clean state. A second problem occurs when sharing files between containers running together in a Pod. The Kubernetes volume abstraction solves both of these problems",
    containerPersistentVolume:
      "PersistentVolume (PV)はストレージクラスを使って管理者もしくは動的にプロビジョニングされるクラスターのストレージの一部です。これはNodeと同じようにクラスターリソースの一部です。PVはVolumeのようなボリュームプラグインですが、PVを使う個別のPodとは独立したライフサイクルを持っています",
    containerLiftCycleTitle: "Define a Command and Arguments for a Container",
    containerLiftCycle:
      "When you create a Pod, you can define a command and arguments for the containers that run in the Pod, The command and arguments that you define cannot be changed after the Pod is created",
    containerHealthCheckTitle: "Liveness Probe、Readiness ProbeおよびStartup Probeを使用する"
  },

  empty: "関連リソースがありません",
  emptyParams: "{0}のモータリングデータがありません",
  emptyInLocation: "選択された【{0}】に関連するリソースがありません。",
  emptyResource: "{0}がありません",
  success: "操作成功",
  error: "操作失敗",

  step: "ステップ",
  addClusterStep: {
    1: "クラウドプロバイダーを選択",
    2: "コンフィグ設定、かつ、クラスタの接続をテスト",
    3: "完成"
  },

  addRegistryStep: {
    1: "レジストリを選択",
    2: "コンフィグ設定、かつ、レジストリの接続をテスト",
    3: "完成"
  },

  registryTips: {
    needAuth: "オンにすると、イメージを取得する際に、自動的にimagePullSecretを作成します",
    test: "イメージレジストリの接続の相通確認"
  },

  clusterTips: {
    test: "クラスタの接続の相通確認",
    monitor: "クラスタの監視するため、モニタリングクライアントを追加します"
  },

  moreConf: "最も設定",

  imageTips: "イメージ・名称のフォーマット：レジストリアドレス/イメージ名称:タグ",

  ingressTips: {
    ingressItemAdd: "ドメイン関連サービスを追加"
  },

  recommand: "最近デプロイ／アップデータの履歴により、リソースを推薦します",

  addApplication: {
    import: "未デプロイ／デプロイ失敗のデータを見つかりましたので、もう一度インポートしますか？",
    leavePrompt: "デプロイしていないデータがありますので、終了しますか？"
  },

  configMapTips: {
    mountPath: "該当設定は、マウントパスで指定されたファイルの場所をコンテナにマウントされます",
    overlay: "コンテナーにマウントされるときに、マウントパスで既存のファイルを上書き",
    mode: "コンテナーにマウントされたときにファイルシステムのアクセス権限を指定します"
  },

  receiverTips: {
    wechatCorpID: "企業Wechatアカウントは唯一のID、自分の企業情報で確認可能",
    wechatAgentID: "第三者企業用のID、自分が作成した第三者企業の詳細画面で確認可能",
    wechatApiSecret: "第三者企業のプライベート鍵、自分が作成した第三者企業の詳細画面で確認可能"
  },

  alarmTips: {
    duration: "値が閾値を超えて且つ持続時間が指定した時間がを超えた場合、アラームが作動される",
    alarmMessageContent: "使用：　$value 指標項目の現在値を取得"
  },

  affinityTips: {
    nodeAffinity: "希望PodがどのNodeにスケジュールされているか",
    podAffinity: "希望PodとどのPodがどのNodeにスケジュールされているか",
    podAntiAffinity: "非希望PodとどのPodがどのNodeにスケジュールされているか",
    nodeKey: "現在のPodと親和で集まったPod、実行中Nodeのラベルキー",
    antiNodeKey: "現在のPodと非親和で集まったPod、実行中Nodeのラベルキー"
  },

  imageHostTips: {
    SWRHost: "フォーマット： swr.{projectName}.myhuaweicloud.com",
    TCRHost: "フォーマット：{registryName}.tencentcloudcr.com"
  }
};
