export default {
  alarm: "Alerts",
  alarmBasic: "Alert Basic Setup",
  alarmReceiver: "Alert Receiver Setup",
  alarmSilence: "Alert Silence Setup",
  smtpHost: "SMTP host",
  slackHost: "Slack host",
  wechatHost: "WeChat host",
  openTLS: "TLS enable",
  sender: "Sender",
  smtpAuthUserName: "SMTP username",
  smtpAuthPassword: "SMTP password",
  wechatApiSecret: "WeChat API key",
  wechatCorpId: "WeChat corp id",
  receiveType: "Receiver type",
  receiverConf: "Receiver setup",
  phoneMessage: "Tel/SMS",
  phoneNumber: "Tel number",
  emailHost: "Email",
  slackChannel: "Slack channel",
  silenceConf: "Silence setup",
  silenceActive: "Silence enable",
  silenceStartTime: "Silence start time",
  silenceEndTime: "Silence end time",
  silenceTime: "Silence time",
  silenceLabel: "Silence labels",
  creator: "Creator",
  expired: "Expired",
  silenceComment: "Silence comments",
  alarmRules: "Alert rules",
  alarmMessages: "Alert messages",
  alarmTrigger: "Alert triggers",
  alarmDuration: "Alert duration",
  alarmMessageHeader: "Alert message header",
  alarmMessageContent: "Alert message contents",
  alarmMessageReceiver: "Alert message receiver",
  alarmTarget: "Alert target",
  alarmTargetOperator: "Alert target operator",
  alarmThreshold: "Alert threshold",
  alarmStatus: {
    active: "Enable",
    unprocessed: "Unprocessed",
    suppressed: "Suppressed"
  },
  alarmStartTime: "Alert start time"
};
