export default {
  alarm: "告警",
  alarmBasic: "基本设置",
  alarmReceiver: "接收设置",
  alarmSilence: "静默设置",
  smtpHost: "SMTP 服务器地址",
  slackHost: "Slack 服务器地址",
  wechatHost: "WeChat 服务器地址",
  openTLS: "启动 TLS 安全连接",
  sender: "发件人",
  smtpAuthUserName: "服务器用户名",
  smtpAuthPassword: "用户名密码",
  wechatApiSecret: "WeChat 服务器密钥",
  wechatCorpId: "WeChat 组织ID",
  receiveType: "接收方式",
  receiverConf: "接收方设置",
  phoneMessage: "电话/短信",
  phoneNumber: "电话号码",
  emailHost: "邮箱地址",
  slackChannel: "频道",
  silenceConf: "静默设置",
  silenceActive: "静默中",
  silenceStartTime: "静默开始时间",
  silenceEndTime: "静默结束时间",
  silenceTime: "静默时间",
  silenceLabel: "静默标签",
  creator: "创建人",
  expired: "已过期",
  silenceComment: "说明",
  alarmRules: "告警规则",
  alarmMessages: "告警消息",
  alarmTrigger: "触发条件",
  alarmDuration: "告警持续时间",
  alarmMessageHeader: "告警消息标题",
  alarmMessageContent: "告警消息内容",
  alarmMessageReceiver: "告警接收方",
  alarmTarget: "告警指标",
  alarmTargetOperator: "运算符",
  alarmThreshold: "阈值",
  alarmStatus: {
    active: "报警中",
    unprocessed: "未处理",
    suppressed: "抑制"
  },
  alarmStartTime: "告警开始时间"
};
