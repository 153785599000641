export default {
  nav: {
    adminNav: "Admin Area",
    userNav: "Navigation",
    userStar: "My Collections",
    resourceAdd: "Fast Creation",

    userManage: "User Management",
    userList: "User List",
    clusterManage: "Cluster Management",
    clusterList: "Cluster List",
    addCluster: "New Cluster",
    registryManage: "Image Registry Management",
    addRegistry: "New Image Registry",

    registryList: "Image Registry List",

    application: "Application",
    namespace: "Namespace",
    configMap: "Configmap",
    secret: "Secret",
    pv: "Persistent Volume",
    pvc: "Persistent Volume Claim",
    storageClass: "StorageClass",
    ingress: "Ingress"
  }
};
