<template>
  <section>
    <router-view :key="key"></router-view>
  </section>
</template>

<script>
export default {
  name: "AppMain",

  data() {
    return {};
  },

  computed: {
    key() {
      return this.$route.fullPath;
    }
  }
};
</script>
<style lang="scss" scoped></style>
