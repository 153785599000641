<template>
  <div class="layout-top">
    <main-header />
    <app-main class="app-main" />
  </div>
</template>

<script>
import MainHeader from "./components/Header.vue";
import AppMain from "./components/AppMain.vue";

export default {
  name: "layout",
  components: {
    MainHeader,
    AppMain
  }
};
</script>

<style lang="scss" scoped>
.layout-top {
  .app-main {
    padding: 10px 20px;
    // width: 96%;
    min-width: 1400px;
    margin: 50px auto 0;
    position: relative;
    box-sizing: border-box;
  }
}
</style>
