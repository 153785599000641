import { getLanguage } from "@/lang/index";
import { getCookies, setCookies, removeCookies } from "@/utils/cookies";
import { getUserInfo } from "@/api/module/user.js";
import { resetRouter } from "@/router/index.js";
import { organizationList } from "api/organization";
import { isArray } from "lodash";

const state = {
  language: getLanguage(),
  token: getCookies("X-KubeStar-Token"),
  userName: getCookies("X-KubeStar-UserName"),
  userId: getCookies("X-KubeStar-UserID"),
  userInfo: {},
  organization: "",
  organizationName: "",
  organizationAlias: "",
  organizationList: [],
  locationList: [],
  recordList: []
};

const mutations = {
  SET_APPINFO: (state, data) => {
    let { token, userName, uuid } = data;
    state.token = token;
    state.userName = userName;
    state.userId = uuid;
    setCookies("X-KubeStar-Token", token);
    setCookies("X-KubeStar-UserName", userName);
    setCookies("X-KubeStar-UserID", uuid);
  },

  SET_LANGUAGE: (state, language) => {
    state.language = language;
    setCookies("language", language);
  },

  SET_USERINFO: (state, data) => {
    state.userInfo = data;
  },

  SET_LOCATIONLIST: (state, list) => {
    state.locationList = list;
  },

  SET_ORGANIZATION: (state, organizationInfo) => {
    let { uuid, name, cnName } = organizationInfo;

    state.organization = uuid;
    state.organizationName = name;
    state.organizationAlias = cnName;

    setCookies("organization", uuid);
  },

  SET_ORGANIZATIONLIST: (state, organizations) => {
    state.organizationList = organizations;
  },

  SET_RECORDLIST: (state, data) => {
    let { fullPath } = data;
    let { name, type } = data.params;
    let { vendor, region, cluster, namespace } = data.query;

    if (state.recordList.length > 8) state.recordList.pop();

    state.recordList.forEach((item, index, arr) => {
      if (item.fullPath === fullPath) {
        arr.splice(index, 1);
      }
    });

    state.recordList.unshift({
      vendor,
      region,
      cluster,
      namespace,
      name,
      type,
      fullPath
    });
  },

  CLEAR_RECORDLIST: state => {
    if (state.recordList.length > 0) state.recordList.pop();
    localStorage.removeItem("recordList");
  }
};

const actions = {
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },

  setAppInfo({ commit }, data) {
    return new Promise(resolve => {
      commit("SET_APPINFO", data);
      resolve();
    });
  },

  setRecordList({ commit }, data) {
    return new Promise(resolve => {
      commit("SET_RECORDLIST", data);
      resolve();
    });
  },

  setLocationList({ commit }, providers) {
    return new Promise(resolve => {
      let locationList = [];

      if (isArray(providers) && providers.length > 0)
        providers.forEach(item => {
          if (item.roles && item.roles.length > 0) {
            let effect = item.roles.map(role => role.effect);
            effect.indexOf("Deny") > -1 ? (item.status = "Deny") : (item.status = "Allow");
          }

          locationList.push({
            uuid: item.provideruuid,
            label: item.kind,
            value: item.kind,
            type: "vendor",
            name: item.name,
            disabled: !item.ready || item.status == "Deny" ? true : false,
            children: [
              {
                label: item.regionID,
                value: item.regionID,
                type: "region",
                children: [
                  {
                    label: item.cluster,
                    value: item.cluster,
                    type: "cluster"
                  }
                ]
              }
            ]
          });
        });

      commit("SET_LOCATIONLIST", locationList);
      resolve(locationList);
    });
  },

  getOrganizationList({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      organizationList(state.userId).then(response => {
        if (response.code === 0) {
          dispatch("setOrganization", response.data.items);
          commit("SET_ORGANIZATIONLIST", response.data.items);
          resolve(response.data.items);
        } else {
          reject("Get OrganizationList Error");
        }
      });
    });
  },

  setOrganization({ commit }, organizations) {
    return new Promise(resolve => {
      if (organizations.length > 0) {
        let organizationInfo = organizations[0];
        for (let item of organizations) {
          if (item.uuid === getCookies("organization")) {
            organizationInfo = item;
          }
        }
        commit("SET_ORGANIZATION", organizationInfo);
      }

      resolve();
    });
  },

  getUserInfo({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      getUserInfo(state.userId).then(response => {
        if (response.code === 0) {
          commit("SET_USERINFO", response.data);
          dispatch("setLocationList", response.data.providers);

          let role = "user";
          const { sysAdminFlag, organizations } = response.data;

          if (sysAdminFlag) {
            role = "system-admin";
          } else {
            if (isArray(organizations) && organizations.length > 0) {
              for (let item of organizations) {
                if (item.uuid === state.organization) {
                  item.adminFlag ? (role = "organization-admin") : (role = "user");
                }
              }
            }
          }

          resolve({ ...response.data, role });
        } else {
          reject("Get User Info Error");
        }
      });
    });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      removeCookies("X-KubeStar-Token");
      removeCookies("X-KubeStar-UserName");
      removeCookies("X-KubeStar-UserID");
      removeCookies("organization");

      commit("SET_USERINFO", {});
      commit("SET_LOCATIONLIST", []);
      commit("SET_ORGANIZATIONLIST", []);
      commit("SET_ORGANIZATION", {});

      commit("CLEAR_RECORDLIST");
      localStorage.removeItem("searchHistory");

      resetRouter();

      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
