export default {
  KubeStarTools: "KubeStar Toolkits",

  tools: {
    clusterMonitor: "Cluster monitoring",
    clusterMonitorDescription: "Multiple dimension cluster monitoring",
    audit: "Audit list",
    auditDescription: "View operation logging",
    importYaml: "On click deployment",
    importYamlDescription: "Deploy by importing present Kubernetes yaml"
  }
};
