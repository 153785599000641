export default {
  PersistentVolume: "存储卷",
  persistentVolumeName: "存储卷名称",
  persistentVolumePath: "挂载路径",
  persistentVolumeReclaimPolicy: "回收策略",
  PersistentVolumeClaims: "存储卷申请",
  claimRef: "持久化卷申请",
  volumeName: "持久化卷",
  storage: "容量",
  accessModes: "访问模式",
  storageClassName: "存储类型",
  volumeMode: "卷类型",
  persistentVolumeSource: "卷来源",
  volumeID: "卷ID",
  fsType: "文件系统",
  readonly: "只读",
  nfsServer: "NFS服务器地址",
  nfsPath: "NFS访问路径"
};
