import http from "../http";

export const organizationAdd = data => {
  return http({
    method: "post",
    data,
    url: `/iam.kubestar.io/organizations`
  });
};

export const organizationEdit = data => {
  return http({
    method: "put",
    data,
    url: `/iam.kubestar.io/organizations`
  });
};

export const organizationList = (id, params) => {
  return http({
    method: "get",
    params,
    url: `/iam.kubestar.io/user/${id}/organizations`
  });
};

export const organizationDelete = id => {
  return http({
    method: "delete",
    url: `/iam.kubestar.io/organizations/${id}`
  });
};

export const organizationJoin = (id, params) => {
  return http({
    method: "get",
    params,
    url: `/iam.kubestar.io/join/organizations/${id}`
  });
};
