<template>
  <div>
    <div class="control-button" @click="dialogVisible = true">
      <svg-icon icon-class="search"></svg-icon>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="no-header-dialog control-dialog"
      :append-to-body="true"
      top="20px"
    >
      <home @routerGo="routerGo" />
    </el-dialog>
  </div>
</template>

<script>
import Home from "@/pages/Home";

export default {
  components: {
    Home
  },

  watch: {
    $route: {
      handler() {
        this.dialogVisible = false;
      },
      immediate: true
    }
  },

  data() {
    return {
      dialogVisible: false
    };
  },

  methods: {
    routerGo() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.control-button {
  height: 32px;
  width: 32px;
  background-color: $header-bg-color;
  border-radius: 50%;
  position: relative;

  .svg-icon {
    position: absolute;
    left: 50%;
    top: 50%;

    height: 18px;
    width: 18px;

    transform: translate(-50%, -50%);
  }
}

.control-dialog {
  /deep/ &.el-dialog {
    width: 90%;
  }
}
</style>
