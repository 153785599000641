export default {
  imageSelect: "Select image",
  imageSelected: "Selected",
  imageTagName: "Image tag",
  imageConfirm: "You haven’t chosen image/image tag, still exit?",
  currentRegistry: "Current image registry: {0}",
  currentImage: "Current image: {0}",
  currentImageTag: "Current image tag: {0}",
  imageName: "Image name",
  imageTag: "Image Tag",
  needAuth: "Need authentication？",
  registry: "Image registry",
  ca: "CA certificate"
};
