export default {
  nav: {
    adminNav: "管理员导航",
    userNav: "服务",
    userStar: "我的收藏",
    resourceAdd: "发布通道",

    userManage: "用户管理",
    userList: "用户列表",
    clusterManage: "集群管理",
    clusterList: "集群列表",
    addCluster: "添加集群",
    registryManage: "镜像仓库管理",
    addRegistry: "添加镜像仓库",

    registryList: "镜像仓库列表",

    application: "发布应用",
    namespace: "创建命名空间",
    configMap: "创建配置",
    secret: "创建密钥",
    pv: "创建存储卷",
    pvc: "创建存储卷申请",
    storageClass: "创建存储卷类型",
    ingress: "创建Ingress"
  }
};
