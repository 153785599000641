export default {
  realtime: "Real Time Monitoring",
  historical: "Historical Trend Monitoring",
  consumedResource: "Consumed",
  requestedResource: "Requested",

  resourceOverview: "Resource overview",
  cluster_cpu_utilization: "Cluster CPU utilization(%)",
  cluster_memory_utilization: "Cluster memory utilization(%)",
  cluster_disk_utilization: "Cluster disk utilization(%)",
  cluster_pod_utilization: "Cluster Pod utilization(%)",
  cpuTotal: "CPU capacity",
  memoryTotal: "Memory capacity",
  diskTotal: "Disk capacity",
  podTotal: "Pod capacity",
  nodeReady: "Ready nodes",
  nodeNotReady: "Not ready nodes",

  pod_cpu_limit: "Pod CPU limit",
  pod_cpu_request: "Pod CPU request",
  pod_cpu_used3mavg: "Pod CPU average utilization in last 3mins",
  pod_memory_limit: "Pod memory limit",
  pod_memory_request: "Pod memory request",
  pod_memory_used: "Pod memory used",
  "pod_networki/o3mavg": "Pod avarage network traffic in last 3mins",

  node_disk_iops: "Disk IOPS",
  node_desc_disk_read_iops: "Read IOPS",
  node_desc_disk_write_iops: "Write IOPS",
  node_disk_throughput: "Disk Throughput",
  node_desc_disk_read_throughput: "Read Throughput",
  node_desc_disk_written_throughput: "Write Throughout",
  node_desc_net: "Network Bandwidth",
  node_desc_net_transmitted: "Network Transmitted",
  node_desc_net_received: "Network Received",
  node_load: "CPU Average Load",
  node_desc_load1: "Load in past 1min",
  node_desc_load5: "Load in past 5mins",
  node_desc_load15: "Load in past 15mins",
  node_desc_cpu_utilization: "CPU utilization",
  node_desc_disk_utilization: "Disk utilization",
  node_desc_inode_utilization: "I-node utilization",
  node_desc_memory_utilization: "Memory utilization",
  node_desc_pod_count: "Pod in total"
};
