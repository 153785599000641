export default {
  unit: {
    second: "秒",
    min: "分钟",
    time: {
      fifteen: "最近15分钟",
      thirty: "最近30分钟",
      oneHour: "最近1小时",
      twoHour: "最近2小时",
      fiveHour: "最近5小时",
      twelveHour: "最近12小时",
      oneDay: "最近1天",
      threeDay: "最近3天",
      sevenDay: "最近7天"
    },
    line: "条",
    all: "全部"
  }
};
