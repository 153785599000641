<template>
  <div>
    <div v-if="loading" class="loading-text">
      <i class="el-icon-loading"></i>
      Loading...
    </div>
    <div class="collection-container" v-else>
      <div class="collection-item" v-for="item in collectionList" :key="item.id">
        <svg-icon :icon-class="item.type"></svg-icon>
        <a href="javascript:;" @click="toCollection(item)">{{ item.name }}</a>
        <i class="el-icon-circle-close" @click="cancelCollection(item)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getCollectionList, cancelCollect } from "api/collection";
export default {
  data() {
    return {
      collectionList: [],
      loading: true
    };
  },

  methods: {
    cancelCollection(item) {
      this.$confirm(this.$t("cancelCollect", [item.name]), this.$t("prompt"), {
        dangerouslyUseHTMLString: true,
        type: "warning"
      })
        .then(() => {
          cancelCollect(item).then(response => {
            if (response.code === 0) {
              this.getCollectionList();
            }
          });
        })
        .catch(() => {});
    },

    toCollection(item) {
      let { region, vendor, cluster, namespace, type, name } = item;

      let query = namespace
        ? { vendor: vendor, region: region, cluster: cluster, namespace: namespace }
        : { vendor: vendor, region: region, cluster: cluster };

      this.$router.push({
        path: `/detail/${type}/${name}`,
        query
      });
    },

    getCollectionList() {
      this.loading = true;
      getCollectionList().then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.collectionList = response.data;
        }
      });
    }
  },

  mounted() {
    this.getCollectionList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.collection-container {
  max-height: 300px;
  overflow-y: scroll;

  .collection-item {
    position: relative;
    transition: 0.2s all ease;
    padding: 6px 24px 6px 6px;
    margin-bottom: 4px;
    @include flex(flex-start, center, nowrap);

    .svg-icon {
      width: 24px;
      margin-right: 8px;
    }

    a {
      width: calc(100% - 20px);
      color: #fff;
      @include text-overflow();
    }

    &:hover {
      background: #000;

      .el-icon-circle-close {
        display: block;
      }
    }

    .el-icon-circle-close {
      display: none;
      color: #fff;
      font-size: 16px;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
</style>
