import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import config from "./modules/config.js";
import app from "./modules/app.js";
import permission from "./modules/permission.js";

export default new Vuex.Store({
  modules: {
    app,
    permission,
    config
  }
});
