export default {
  PersistentVolume: "Persistent Volumes",
  persistentVolumeName: "Persistent Volume name",
  persistentVolumePath: "Mount path",
  persistentVolumeReclaimPolicy: "Reclaim policy",
  PersistentVolumeClaims: "Persistent Volume Claims",
  claimRef: "Persistent Volume Claim name",
  volumeName: "Persistent Volume",
  storage: "Storage capacity",
  accessModes: "Access mode",
  storageClassName: "StorageClass name",
  volumeMode: "Volume type",
  persistentVolumeSource: "Persistent Volume source",
  volumeID: "Volume ID",
  fsType: "File system",
  readonly: "Readonly",
  nfsServer: "NFS server",
  nfsPath: "NFS path"
};
