import http from "../http";
import { getCookies } from "@/utils/cookies";

export const addCollect = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/collections`
  });
};

export const getCollectionList = () => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/users/${getCookies("X-KubeStar-UserID")}/collections`
  });
};

export const isCollected = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/users/${getCookies("X-KubeStar-UserID")}/collections`
  });
};

export const cancelCollect = data => {
  return http({
    method: "delete",
    data,
    url: `/manage.kubestar.io/users/${getCookies("X-KubeStar-UserID")}/collections`
  });
};
