export default {
  iam: "IAM管理",
  organizationList: "企业列表",
  userGroupList: "用户组列表",
  roleList: "角色列表",
  userList: "用户列表",
  contacter: "联系人",
  contacterEmail: "联系人邮箱",
  organizationAlias: "企业别名",
  invitationInfo: "邀请信息",
  organizationID: "企业ID",
  inviterID: "邀请人ID",
  invitationCode: "邀请码",
  createOrganization: "创建企业",
  joinOrganization: "加入企业",
  belongOrganization: "所属企业",
  userGroupAddUser: "添加用户到此用户组",
  userGroupPermission: "用户组权限",
  createUser: "创建用户",
  rolePermission: "角色权限",
  roleAllPermission: "全部权限",
  userPermission: "用户权限",
  resetAKSK: "重置AK/SK",
  userID: "用户ID",
  bindUserGroup: "绑定用户组",
  createUserGroup: "创建用户组"
};
