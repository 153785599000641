export default {
  prompt: "提示",
  delete: "确定要删除{0}吗？",
  restart: "确定要重启{0}吗？",
  pause: "确定要暂停{0}吗？",
  resume: "确定要恢复{0}吗？",
  confirm: "请输入名字进行确认",
  collect: "确定要收藏{0}吗？",
  unschedulable: "确定要停止调度{0}吗？",
  recover: "确定要恢复调度{0}吗？",
  cancelCollect: "确定要取消收藏{0}吗？",
  tokenError: "Token过期，请重新登录。",
  providerTips: "用户未关联集群，无法进行集群相关的操作",
  batchDelete: "确定要删除以下资源吗？"
};
