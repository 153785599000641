export default {
  nav: {
    adminNav: "管理者ナビゲーション",
    userNav: "サービス",
    userStar: "お気に入り",
    resourceAdd: "リソース追加",

    userManage: "アカウント管理",
    userList: "アカウントリスト",
    clusterManage: "クラスタ管理",
    clusterList: "クラスタリスト",
    addCluster: "クラスタ追加",
    registryManage: "レジストリ管理",
    addRegistry: "レジストリ追加",

    registryList: "イメージレジストリリスト",

    application: "アプリケーションのデプロイ",
    namespace: "ネームスペース作成",
    configMap: "コンフィグ作成",
    secret: "セキュリティキー作成",
    pv: "Pv作成",
    pvc: "Pvc作成",
    storageClass: "ストレージタイプ作成",
    ingress: "Ingress作成"
  }
};
