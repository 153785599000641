export default {
  imageSelect: "イメージ選択",
  imageSelected: "選択済",
  imageTagName: "タグ名称",
  imageConfirm: "イメージ、または、イメージタグを選択していません。退出してもよろしいですか？",
  currentRegistry: "使用レジストリ: {0}",
  currentImage: "使用イメージ: {0}",
  currentImageTag: "使用イメージタグ: {0}",
  imageName: "イメージ名称",
  imageTag: "イメージタグ",
  needAuth: "認証必要ですか？",
  registry: "レジストリ",
  ca: "CA証明書"
};
