export default {
  ConfigMap: "配置",
  configMapName: "配置名称",
  configMapRootPath: "配置绝对路径",
  mountPath: "挂载路径",
  configMapItem: "配置项",
  mountContainer: "挂载容器组：",
  mountFile: "挂载文件",
  overlay: "是否覆盖",
  mode: "读写权限"
};
