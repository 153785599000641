export default {
  auditList: "審査リスト",
  auditUserName: "審査ユーザ名",
  auditResource: "リソース申請",
  auditReqMethod: "申請方法",
  auditHandleTime: "操作時間",
  auditReqURI: "申請アドレス",
  auditRespStatusCode: "申請ステータスコード",
  auditBusinessStatusCode: "業務ステータスコード",
  auditBusinessMessage: "業務メッセージ",
  auditSearchList: "検索条件",
  auditReqHeader: "申請ヘッダ",
  auditReqBody: "申請本文"
};
