<template>
  <span>
    <span @click="getVersion">{{ $t("handle.aboutUs") }}</span>

    <el-dialog :title="$t('handle.aboutUs')" :visible.sync="dialogVisible" width="600px" :append-to-body="true">
      <div class="about-info">
        <img :src="logo" />
        <div class="content">
          <div class="title">KubeStar</div>
          <div class="desc">Bring Cloud Native to Enterprises</div>
        </div>
      </div>

      <div class="about-content">
        {{ $t("aboutUs") }}
      </div>

      <div class="title">{{ version }}</div>
    </el-dialog>
  </span>
</template>

<script>
import { version } from "api/app";
import logo from "@/assets/logo.svg";
export default {
  data() {
    return {
      logo,
      version: "",
      dialogVisible: false
    };
  },

  methods: {
    getVersion() {
      version().then(response => {
        if (response.code === 0) {
          this.version = response.data;
          this.dialogVisible = true;
          // this.$alert(response.data, this.$t("version"));
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.about-info {
  @include flex(flex-start);

  & > img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .content {
    .title {
      font-size: 14px;
    }
  }
}

.about-content {
  font-size: 12px;
  color: $color-sub;

  margin: 10px 0;
}
</style>
