import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout/index.vue";

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/pages/Redirect/index.vue")
      }
    ]
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/Login/index.vue")
  },

  {
    path: "/swagger",
    component: Layout,
    name: "Swagger",
    children: [
      {
        path: "/",
        component: () => import("@/pages/Swagger/index.vue")
      }
    ]
  },

  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "home",
        component: () => import("@/pages/Home/index.vue"),
        name: "Home"
      },

      {
        path: "list/:type",
        component: () => import("@/pages/List/index.vue"),
        name: "List"
      },

      {
        path: "detail/:type/:name",
        component: () => import("@/pages/Detail/index.vue"),
        name: "Detail"
      },

      {
        path: "add",
        component: () => import("@/pages/Add/index.vue"),
        name: "AddResource"
      },

      {
        path: "add/import",
        component: () => import("@/pages/Add/Import.vue"),
        name: "AddImport"
      },

      {
        path: "add/Application",
        component: () => import("@/pages/Application/Add/index.vue"),
        name: "AddApplication"
      },

      {
        path: "add/Deployment",
        component: () => import("@/pages/Application/Add/index.vue"),
        name: "AddDeployment"
      },

      {
        path: "add/StatefulSet",
        component: () => import("@/pages/Application/Add/index.vue"),
        name: "AddStatefulSet"
      },

      {
        path: "add/CronJob",
        component: () => import("@/pages/Application/Add/index.vue"),
        name: "AddCronJob"
      },

      {
        path: "add/DaemonSet",
        component: () => import("@/pages/Application/Add/index.vue"),
        name: "AddDaemonSet"
      },

      {
        path: "add/Service",
        component: () => import("@/pages/Service/Add.vue"),
        name: "AddService"
      },

      {
        path: "add/Namespace",
        component: () => import("@/pages/Namespace/Add.vue"),
        name: "AddNamespace"
      },

      {
        path: "add/ConfigMap",
        component: () => import("@/pages/ConfigMap/Add.vue"),
        name: "AddConfigMap"
      },

      {
        path: "add/Secret",
        component: () => import("@/pages/ConfigMap/Add.vue"),
        name: "AddSecret"
      },

      {
        path: "add/PersistentVolume",
        component: () => import("@/pages/PV/AddPV.vue"),
        name: "AddPersistentVolume"
      },

      {
        path: "add/PersistentVolumeClaims",
        component: () => import("@/pages/PV/AddPVC.vue"),
        name: "AddPersistentVolumeClaims"
      },

      {
        path: "add/Ingress",
        component: () => import("@/pages/Ingress/Add.vue"),
        name: "AddIngress"
      },

      {
        path: "add/StorageClass",
        component: () => import("@/pages/StorageClass/Add.vue"),
        name: "AddStorageClass"
      },

      {
        path: "notFound",
        component: () => import("@/pages/Error/NotFound.vue"),
        name: "Empty"
      },

      {
        path: "audit",
        component: () => import("@/pages/Audit/List.vue")
      },

      {
        path: "cost",
        component: () => import("@/pages/Cost/index.vue")
      },

      {
        path: "/cluster-dashboard",
        component: () => import("@/pages/Dashboard/Cluster/index.vue")
      },

      {
        path: "/dashboard-custom",
        component: () => import("@/pages/Dashboard/Custom/index.vue"),
        redirect: "/dashboard-custom/category",
        children: [
          {
            path: "category",
            component: () => import("@/pages/Dashboard/Custom/CategoryList.vue")
          },

          {
            path: "category/:category/dashboard",
            component: () => import("@/pages/Dashboard/Custom/DashboardList.vue")
          },

          {
            path: "dashboard/:dashboard/detail",
            component: () => import("@/pages/Dashboard/Custom/Dashboard.vue")
          }
        ]
      },

      {
        path: "/appstore",
        component: () => import("@/pages/AppStore/index.vue"),
        redirect: "/appstore/list",
        children: [
          {
            path: "list",
            component: () => import("@/pages/AppStore/List.vue")
          },

          {
            path: "detail/:name/:version",
            component: () => import("@/pages/AppStore/Detail.vue")
          }
        ]
      }
    ]
  },

  {
    path: "/dashboard/:dashboard/config",
    component: () => import("@/pages/Metric/index.vue")
  },

  {
    path: "/logs/:name/:container",
    component: () => import("@/components/Logs.vue")
  },

  {
    path: "/shell/:name/:container",
    component: () => import("@/components/WebShell.vue")
  }
];

export const asyncRoutes = [
  {
    path: "/admin",
    component: Layout,
    children: [
      {
        path: "user",
        component: () => import("@/pages/User/List.vue"),
        name: "User"
      },

      {
        path: "user/add",
        component: () => import("@/pages/User/Add.vue"),
        name: "User"
      },

      {
        path: "alarm/config",
        component: () => import("@/pages/Alarm/Config.vue")
      },

      {
        path: "alarm/receiver",
        component: () => import("@/pages/Alarm/Receiver.vue")
      },

      {
        path: "alarm/silence",
        component: () => import("@/pages/Alarm/Silence.vue")
      },

      {
        path: "cluster/add",
        component: () => import("@/pages/Cluster/Add.vue")
      },

      {
        path: "cluster/list",
        component: () => import("@/pages/Cluster/List.vue")
      },

      {
        path: "registry/add",
        component: () => import("@/pages/Registry/Add.vue")
      },

      {
        path: "registry/list",
        component: () => import("@/pages/Registry/List.vue")
      },

      {
        path: "organization",
        component: () => import("@/pages/Organization/List.vue"),
        name: "Organization"
      },

      {
        path: "organization/add",
        component: () => import("@/pages/Organization/Add.vue"),
        name: "OrganizationAdd"
      },

      {
        path: "group",
        component: () => import("@/pages/Group/List.vue"),
        name: "Groud"
      },

      {
        path: "group/add",
        component: () => import("@/pages/Group/Add.vue"),
        name: "GroupAdd"
      },

      {
        path: "role",
        component: () => import("@/pages/Role/List.vue"),
        name: "Role"
      },

      {
        path: "role/add",
        component: () => import("@/pages/Role/Add.vue"),
        name: "RoleAdd"
      }
    ]
  }
];

const createRouter = () =>
  new Router({
    routes: constantRoutes
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
