import aws from "@/assets/provider/aws.svg";
import aliyun from "@/assets/provider/aliyun.svg";
import gcp from "@/assets/provider/gcp.svg";
import onpremise from "@/assets/provider/onpremise.svg";
import tencentcloud from "@/assets/provider/tencentcloud.svg";
import huaweicloud from "@/assets/provider/huaweicloud.svg";
import docker from "@/assets/provider/docker.svg";
import harbor from "@/assets/provider/harbor.svg";
import ucloud from "@/assets/provider/ucloud.svg";

export default {
  data() {
    return {
      aws,
      aliyun,
      gcp,
      onpremise,
      tencentcloud,
      huaweicloud,
      docker,
      harbor,
      ucloud
    };
  }
};
