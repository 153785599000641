import http from "../http";

export const supportRegistries = () => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/supportregistries`
  });
};

export const registryList = (id, params) => {
  return http({
    method: "get",
    params,
    url: `/manage.kubestar.io/organizations/${id}/registries`
  });
};

export const registryTest = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/registries/test`
  });
};

export const addRegistry = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/registries`
  });
};

export const deleteRegistry = data => {
  return http({
    method: "delete",
    data,
    url: `/manage.kubestar.io/registries`
  });
};

export const updateRegistry = data => {
  return http({
    method: "put",
    data,
    url: `/manage.kubestar.io/registries`
  });
};

export const getRegistries = provider => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/providers/${provider}/registries`
  });
};

export const imageList = registryId => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/registries/${registryId}/repositories`
  });
};

export const imageTagList = (registryId, image, headers = {}) => {
  return http({
    method: "get",
    headers,
    url: `/manage.kubestar.io/registries/${registryId}/repositories/${image}`
  });
};

export const imageSplit = (provider, params) => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/providers/${provider}/split/repositories`,
    params
  });
};

export const dockerHubImageList = params => {
  return http({
    method: "get",
    params,
    url: `/manage.kubestar.io/dockerhub/repositories`
  });
};
