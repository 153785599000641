import Cookies from "js-cookie";

export function getCookies(TokenKey) {
  return Cookies.get(TokenKey);
}

export function setCookies(TokenKey, token) {
  return Cookies.set(TokenKey, token);
}

export function removeCookies(TokenKey) {
  return Cookies.remove(TokenKey);
}
