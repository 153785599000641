import { configs } from "@/api/module/app.js";

const state = {
  configs: {}
};

const mutations = {
  SET_CONFIGS: (state, data) => {
    state.configs = data;
  }
};

const actions = {
  setConfigs({ commit }) {
    return new Promise(resolve => {
      configs().then(response => {
        if (response.code === 0) {
          let configs = {
            MFA: response.data.MFA
          };

          commit("SET_CONFIGS", configs);
        }

        resolve();
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
