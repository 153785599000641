import http from "../http";

export const namespaceList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/namespaces`
  });
};

export const namespaceListByLocation = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces`,
    data
  });
};

export const namespaceSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/namespaces`,
    data,
    headers
  });
};

export const namespaceDetail = payload => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/namespaces/${payload.namespace}`
  });
};

export const namespaceDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.name}`
  });
};

export const resourceQuotaSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/resourcequotas`,
    data,
    headers
  });
};

export const resourceQuotaUpdate = (data, headers) => {
  return http({
    method: "put",
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/resourcequotas/${data.metadata.name}`,
    data,
    headers
  });
};

export const limitrangesSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/limitranges`,
    data,
    headers
  });
};

export const limitrangesUpdate = (data, headers) => {
  return http({
    method: "put",
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/limitranges/${data.metadata.name}`,
    data,
    headers
  });
};
