export default {
  unit: {
    second: "秒",
    min: "分",
    time: {
      fifteen: "最近15分",
      thirty: "最近30分",
      oneHour: "最近1時間",
      twoHour: "最近2時間",
      fiveHour: "最近5時間",
      twelveHour: "最近12時間",
      oneDay: "最近1日",
      threeDay: "最近3日",
      sevenDay: "最近7日"
    },
    line: "レコード",
    all: "全部"
  }
};
