export default {
  realtime: "实时监控",
  historical: "趋势监控",
  consumedResource: "实际使用量",
  requestedResource: "已申请量",

  resourceOverview: "资源总览",
  cluster_cpu_utilization: "CPU利用率(%)",
  cluster_memory_utilization: "内存利用率(%)",
  cluster_disk_utilization: "磁盘利用率(%)",
  cluster_pod_utilization: "POD使用率(%)",
  cpuTotal: "CPU总量",
  memoryTotal: "内存总量",
  diskTotal: "磁盘总量",
  podTotal: "POD总数",
  nodeReady: "在线节点",
  nodeNotReady: "故障节点",

  pod_cpu_limit: "CPU最大配额",
  pod_cpu_request: "CPU最小配额",
  pod_cpu_used3mavg: "CPU3分钟内平均使用量",
  pod_memory_limit: "内存最大配额",
  pod_memory_request: "内存最小配额",
  pod_memory_used: "内存使用量",
  "pod_networki/o3mavg": "3分钟内平均网络速率",

  node_disk_iops: "IOPS",
  node_desc_disk_read_iops: "读",
  node_desc_disk_write_iops: "写",
  node_disk_throughput: "磁盘吞吐",
  node_desc_disk_read_throughput: "读",
  node_desc_disk_written_throughput: "写",
  node_desc_net: "网络带宽",
  node_desc_net_transmitted: "出",
  node_desc_net_received: "入",
  node_load: "CPU平均负载",
  node_desc_load1: "1分钟",
  node_desc_load5: "5分钟",
  node_desc_load15: "15分钟",
  node_desc_cpu_utilization: "CPU使用情况",
  node_desc_disk_utilization: "磁盘利用率",
  node_desc_inode_utilization: "inode使用率",
  node_desc_memory_utilization: "内存使用率",
  node_desc_pod_count: "POD数量"
};
