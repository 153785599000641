<template>
  <div>
    <el-form :model="form" size="small" ref="form">
      <div v-for="(item, index) in form.labels" :key="index">
        <div v-if="item.key != 'kubectl.kubernetes.io/last-applied-configuration'">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item
                :prop="'labels.' + index + '.key'"
                :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
              >
                <el-input v-model="item.key" size="small">
                  <template slot="prepend">
                    Key
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="11">
              <el-form-item
                :prop="'labels.' + index + '.value'"
                :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
              >
                <el-input v-model="item.value" size="small">
                  <template slot="prepend">
                    Value
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="2">
              <el-button icon="el-icon-delete" circle @click="form.labels.splice(index, 1)" size="small"></el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>

    <el-button
      type="primary"
      size="small"
      icon="plus"
      @click="form.labels.push({ key: '', value: '' })"
      style="margin-right: 10px;"
    >
      + {{ $t("handle.add") }}
    </el-button>

    <el-dropdown v-if="editType === 'annotation'">
      <el-button type="primary" size="small" icon="plus">
        + {{ $t("handle.addMetricTemplate") }} <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="addMetricTemplateBlack">
          {{ $t("handle.addMetricTemplateBlack") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="addMetricTemplateWhite">
          {{ $t("handle.addMetricTemplateWhite") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { cloneDeep, transform } from "lodash";
export default {
  name: "EditLabels",

  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },

    editType: String
  },

  data() {
    return {
      form: {
        labels: []
      }
    };
  },

  methods: {
    addMetricTemplateBlack() {
      this.form.labels.push(
        ...[
          { key: "prometheus.io/http-probe", value: "true" },
          { key: "prometheus.io/http-probe-port", value: "8080" },
          { key: "prometheus.io/http-probe-path", value: "/probe" }
        ]
      );
    },

    addMetricTemplateWhite() {
      this.form.labels.push(
        ...[
          { key: "prometheus.io/http-metric", value: "true" },
          { key: "prometheus.io/http-metric-scheme", value: "http" },
          { key: "prometheus.io/http-metric-port", value: "8080" },
          { key: "prometheus.io/http-metric-path", value: "/metrics" }
        ]
      );
    }
  },

  mounted() {
    this.form.labels = transform(
      cloneDeep(this.data),
      function(result, value, key) {
        result.push({ key, value });
      },
      []
    );
  }
};
</script>
