export default {
  realtime: "リアルタイム監視",
  historical: "トレンド監視",
  consumedResource: "使用量",
  requestedResource: "予約",

  resourceOverview: "リソース概要",
  cluster_cpu_utilization: "CPU使用率 (%)",
  cluster_memory_utilization: "メモリ使用率(%)",
  cluster_disk_utilization: "ディスク使用率(%)",
  cluster_pod_utilization: "POD使用率(%)",
  cpuTotal: "CPU量",
  memoryTotal: "メモリ量",
  diskTotal: "ディスク量",
  podTotal: "POD数",
  nodeReady: "使用可能ノート",
  nodeNotReady: "使用不可ノード",

  pod_cpu_limit: "CPU资源上限",
  pod_cpu_request: "CPU下限",
  pod_cpu_used3mavg: "CPU3分間平均使用量",
  pod_memory_limit: "メモリ上限",
  pod_memory_request: "メモリ下限",
  pod_memory_used: "メモリ使用量",
  "pod_networki/o3mavg": "3分間平均ネットワークスピード",

  node_disk_iops: "ストレージIOPS",
  node_desc_disk_read_iops: "リードIOPS",
  node_desc_disk_write_iops: "ライトIOPS",
  node_disk_throughput: "ストレージスループット",
  node_desc_disk_read_throughput: "リードスループット",
  node_desc_disk_written_throughput: "ライトスループット",
  node_desc_net: "ネットワーク帯域幅",
  node_desc_net_transmitted: "ネットワーク送信量",
  node_desc_net_received: "入ネットワーク受信量",
  node_load: "CPU平均負荷",
  node_desc_load1: "１分間",
  node_desc_load5: "５分間",
  node_desc_load15: "１５分間",
  node_desc_cpu_utilization: "CPU使用状況",
  node_desc_disk_utilization: "ディスク使用率",
  node_desc_inode_utilization: "inode使用率",
  node_desc_memory_utilization: "メモリ使用率",
  node_desc_pod_count: "POD数"
};
