export default {
  KubeStarTools: "工具集",

  tools: {
    clusterMonitor: "集群监控",
    clusterMonitorDescription: "为集群提供多维度的监控",
    audit: "审计列表",
    auditDescription: "查看操作日志",
    importYaml: "一键发布",
    importYamlDescription: "通过导入现有Kubernetes yaml配置进行发布"
  }
};
