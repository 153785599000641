export default {
  auditList: "Audits",
  auditUserName: "Username",
  auditResource: "Resource",
  auditReqMethod: "Request method",
  auditHandleTime: "Request timestamp",
  auditReqURI: "Request URI",
  auditRespStatusCode: "Response status code",
  auditBusinessStatusCode: "Business status code",
  auditBusinessMessage: "Business message",
  auditSearchList: "Search parameters",
  auditReqHeader: "Request header",
  auditReqBody: "Request body"
};
