import http from "../http";



export const providerList = () => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/supportproviders`
  });
};

export const regionListByProvider = name => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/providers/${name}`
  });
};

export const clusterListByProvider = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/providers/${data.kind}/clusters`
  });
};

export const clusterTest = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/providers/${data.kind}/clusters/${data.cluster}/test`
  });
};

export const addProvider = data => {
  return http({
    method: "post",
    data,
    url: `/manage.kubestar.io/providers/${data.kind}/clusters/${data.cluster}`
  });
};

export const updateProvider = data => {
  return http({
    method: "put",
    data,
    url: `/manage.kubestar.io/providers/${data.kind}/clusters/${data.cluster}`
  });
};

export const providers = (id, params) => {
  return http({
    method: "get",
    params,
    url: `/manage.kubestar.io/organizations/${id}/providers`
  });
};

export const providerDelete = data => {
  return http({
    method: "delete",
    data,
    url: `/manage.kubestar.io/providers/${data.kind}/clusters/${data.cluster}`
  });
};

export const recentRecords = () => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/records`
  });
};

export const version = () => {
  return http({
    method: "get",
    url: `/version`
  });
};

export const addMonitors = data => {
  return http({
    method: "post",
    url: "/manage.kubestar.io/monitors",
    data
  });
};

export const updateMonitor = data => {
  return http({
    method: "put",
    url: "/manage.kubestar.io/monitors",
    data
  });
};

export const deleteMonitor = id => {
  return http({
    method: "delete",
    url: `/manage.kubestar.io/monitors/${id}`
  });
};

export const monitorList = id => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/monitors/providers/${id}`
  });
};

export const configs = () => {
  return http({
    method: "get",
    url: "/system.kubestar.io/configs"
  });
};
