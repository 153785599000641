export default {
  rules: {
    require: "必填信息",
    pattern: "格式不正确，请重新输入。",
    configMapFileName: "同一文件不可以挂载不同的配置项",
    location: "请选择发布位置和命名空间",
    existed: "{0}名称已存在，请更换。",

    login: {
      passwordStrength: "密码强度",
      password: "密码必须包含数字和小写字母，长度至少为 6 位",
      passwordConfirm: "请再次输入密码",
      passwordDiff: "两次输入密码不一致",
      email: "请输入正确的邮箱地址",
      userName: "用户名的首字母必须为英文小写，并且只能输入字母数字字符，下划线，连字符和点。"
    }
  }
};
