export default {
  prompt: "Prompt",
  delete: "Are you sure to delete {0}?",
  restart: "Are you sure to restart {0}?",
  pause: "Are you sure to pause {0}?",
  resume: "Are you sure to recover {0}?",
  confirm: "Please enter name to confirm",
  collect: "Are you sure to collect {0}?",
  unschedulable: "Are you sure to unschedule {0}?",
  recover: "Are you sure to recover schedule {0}?",
  cancelCollect: "Are you sure to cancel collect {0}?",
  tokenError: "Token is experied, please login again",
  providerTips: "User is not binded to any cluster, no rcluster elevant operation is allowed",
  batchDelete: "Are you sure the below resource will be deleted?"
};
