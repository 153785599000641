<template>
  <div>
    <el-alert
      :title="$t('whatIsAKSK')"
      type="info"
      :description="$t('AKSKTips')"
      show-icon
      style="margin-bottom: 10px;"
    >
    </el-alert>

    <div class="name-value">
      <span class="name">accessKey:</span>
      <span class="value">
        <i class="el-icon-document-copy" v-clipboard:copy="form.accessKey"></i>
        {{ form.accessKey || "-" }}
      </span>
    </div>

    <div class="name-value">
      <span class="name">secretKey:</span>
      <span class="value">
        <i class="el-icon-document-copy" v-clipboard:copy="form.secretKey"></i>
        {{ form.secretKey || "-" }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: Object
  }
};
</script>

<style></style>
