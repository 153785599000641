export default {
  unit: {
    second: "Seconds",
    min: "mins",
    time: {
      fifteen: "Last 15 mins",
      thirty: "Last 30 mins",
      oneHour: "Last 1 hour",
      twoHour: "Last 2 hour",
      fiveHour: "Last 5 hour",
      twelveHour: "Last 12 hours",
      oneDay: "Last 1 day",
      threeDay: "Last 3 day",
      sevenDay: "Last 7 day"
    },
    line: "lines",
    all: "All"
  }
};
