<template>
  <div>
    <el-tag
      v-for="(value, name) in labels"
      :key="name"
      closable
      :disable-transitions="false"
      @close="handleClose(name)"
    >
      {{ name }}:{{ value ? value : "-" }}
    </el-tag>

    <el-input
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="small"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
      style="width: 100px;"
    >
    </el-input>
    <el-button v-else size="small" @click="showInput">+ {{ $t("handle.add") }}</el-button>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "AddLabels",
  props: {
    labels: {
      type: Object
    }
  },
  data() {
    return {
      inputVisible: false,
      inputValue: ""
    };
  },
  methods: {
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleClose(name) {
      let labels = cloneDeep(this.labels);
      delete labels[name];
      this.$emit("update:labels", labels);
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;

      if (inputValue) {
        let key = inputValue.split(":")[0].trim();
        let value = inputValue.split(":")[1] ? inputValue.split(":")[1].trim() : "";

        let labels = cloneDeep(this.labels);
        labels[key] = value;
        this.$emit("update:labels", labels);
      }
      this.inputVisible = false;
      this.inputValue = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
