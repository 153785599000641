export default {
  alarm: "アラーム",
  alarmBasic: "基本設定",
  alarmReceiver: "受信設定",
  alarmSilence: "サイレンス設定",
  smtpHost: "SMTP サーバーアドレス",
  slackHost: "Slack サーバーアドレス",
  wechatHost: "WeChat サーバーアドレス",
  openTLS: "起動 TLS 安全接続",
  sender: "差出人",
  smtpAuthUserName: "サーバーのユーザ名",
  smtpAuthPassword: "ユーザのパスワード",
  wechatApiSecret: "WeChat サーバーのプライベート鍵",
  wechatCorpId: "WeChat 組織ID",
  receiveType: "受信方法",
  receiverConf: "受信側の設定",
  phoneMessage: "電話/SMS",
  phoneNumber: "電話番号",
  emailHost: "メールアドレス",
  slackChannel: "チャンネル",
  silenceConf: "サイレンス設定",
  silenceActive: "サイレンス状態",
  silenceStartTime: "サイレンス開始時間",
  silenceEndTime: "サイレンス終了時間",
  silenceTime: "サイレンス時間",
  silenceLabel: "サイレンスラベル",
  creator: "作成者",
  expired: "期限切れ",
  silenceComment: "説明",
  alarmRules: "アラームルール",
  alarmMessages: "アラームメッセージ",
  alarmTrigger: "トリガー条件",
  alarmDuration: "アラーム持続時間",
  alarmMessageHeader: "アラームメッセージのタイトル",
  alarmMessageContent: "アラームメッセージの内容",
  alarmMessageReceiver: "アラームの受信側",
  alarmTarget: "アラームのターゲット",
  alarmTargetOperator: "オペレーター",
  alarmThreshold: "閾値",
  alarmStatus: {
    active: "アラーム発生",
    unprocessed: "未処理",
    suppressed: "抑制された"
  },
  alarmStartTime: "アラーム開始時間"
};
