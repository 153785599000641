<template>
  <div class="add-form">
    <div class="list-title">
      {{ $t("joinOrganization") }}
    </div>

    <el-form :model="form" ref="form" label-position="top" size="small">
      <el-form-item
        :label="$t('organizationID')"
        prop="organization"
        :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
      >
        <el-input v-model.trim="form.organization"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('inviterID')"
        prop="inviter"
        :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
      >
        <el-input v-model.trim="form.inviter"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('invitationCode')"
        prop="invitationCode"
        :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
      >
        <el-input v-model.trim="form.invitationCode"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" plain size="small" :loading="loading" @click="submit">
          {{ $t("handle.submit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { organizationJoin } from "api/organization";

export default {
  props: {
    afterSubmit: {
      type: Function,
      default: function() {}
    }
  },

  data() {
    return {
      form: {
        organization: "", //要加入的组织ID
        inviter: "", //邀请人
        invitationCode: "" //邀请码
      },

      loading: false
    };
  },

  methods: {
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.invitee = this.userId;

          this.loading = true;

          organizationJoin(this.form.organization, {
            inviter: this.form.inviter,
            invitationCode: this.form.invitationCode,
            invitee: this.userId
          }).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.afterSubmit();
            }
          });
        }
      });
    }
  },

  computed: {
    userId() {
      return this.$store.state.app.userId;
    }
  }
};
</script>

<style></style>
